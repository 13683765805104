import NavItemBase from 'reactstrap/lib/NavItem'
import NavLinkBase from 'reactstrap/lib/NavLink'
import styled, { css } from 'styled-components'
import { INavItem } from './types'

export const NavItem = styled(NavItemBase)<INavItem>`
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`

export const NavLink = styled(NavLinkBase)`
  ${({ theme }) =>
    theme &&
    css`
      background: linear-gradient(45deg, ${theme.colors.primary} 0%, ${theme.colors.secondary} 100%);
      color: #ffffff !important;
    `}
  align-items: center;
  border-radius: 25px;
  display: flex;
  font-size: 0.88rem;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 5px;
  padding: 4px 10px;
  width: 100%;
`

export const Icon = styled.i`
  color: #fdd916;
  font-size: 0.8rem;
`

export const Text = styled.span`
  font-size: 0.85rem;
`
