import React, { useCallback } from 'react'
import { Col } from 'reactstrap'
// import Select from 'react-select'
import { IFieldSelect } from './types'
// import { notNullUndefined } from '../../Utils'
import { DateSelect } from '../DateSelect'
import moment from 'moment'
import styled from 'styled-components'
import { notNullUndefined } from '../../Utils'

const InputText = styled.input`
  width: 100%;
  background: #f7f9fb;
  border-radius: 15px;
  border: 0px solid #dee5ef;
  padding: 5px 8px;

  &[disabled] {
    opacity: 0.5;
  }
`
export const FieldSelectDate = <T extends IFieldSelect<T>>({
  afterContent,
  className,
  disabled,
  id,
  label = 'Data *',
  onChange,
  selectedValue,
  size = { lg: 6, xl: 6 }
}: IFieldSelect<T>) => {
  const selectedDate = useCallback(() => {
    if (!notNullUndefined(selectedValue)) return moment()?.toDate()

    const date = moment(selectedValue, 'DD/MM/YYYY').toDate()
    return date
  }, [selectedValue])

  return (
    <Col lg={size.lg} xl={size.xl} className={className}>
      {label && <label>{label}</label>}

      {disabled ? (
        <InputText type="text" disabled value={moment(selectedValue, 'DD/MM/YYYY').format('DD/MM/YYYY')} />
      ) : (
        <DateSelect className={className} id={id} onChange={onChange} selected={selectedDate()} />
      )}
      {/* <Select
        key={getValue()?.label}
        id={id}
        selected={getValue() || ''}
        disabled={disabled}
        onChange={onChange}
        className={className}
        loading={loading}
        options={options || []}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={getValue()}
        grayscale
        borderless
      /> */}

      {!!afterContent && afterContent}
    </Col>
  )
}
