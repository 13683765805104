import React from 'react'
import { IframeVimeo } from './components/VideoIframe/Iframe'
import { Pagination } from './components/Pagination'
import { MapProps } from './types'

export const HelpHome = () => (
  <div>
    <h3>Bem vindo(a) ao Vista!</h3>

    <p>Nesta tela você verá algumas informações resumidas sobre a sua saúde financeira.</p>

    <p>
      No gráfico abaixo você poderá comparar os seus investimentos, receitas, despesas e dívidas que foram
      realizados ao longo do prazo escolhido.
    </p>

    <p>Por padrão este prazo será o mês atual.</p>

    <h4>Tutorial:</h4>

    <IframeVimeo title="Home (Ajuda)" videoId="768236564" />
  </div>
)

export const HelpPlanosSonhos = () => (
  <Pagination>
    <div>
      <p>
        Aqui é o coração de todo o planejamento. Desejamos que você conquiste e festeje o maior número
        possível de sonhos e planos.
      </p>

      <p>
        Sonhos são todos os seus desejos, tudo aquilo que pensa um dia em realizar. Não deixe de registrar
        aqui os seus sonhos já realizados!
      </p>

      <p>
        Já os planos são os sonhos que passam a ter um planejamento financeiro, e a partir dai inserimos
        informações como prazo, valor, patrimônio alocado e outros dados. Queremos te ajudar a conquistar seus
        sonhos, mas primeiro vamos planejar.
      </p>

      <p>
        Todos os planos têm uma barra de status, que irá aumentando o percentual a medida em que você destina
        patrimônio para a realização deste. Ao chegar nos 100% quer dizer que você possui o patrimônio
        almejado para isso, mas não necessariamente já realizou o plano.
      </p>

      <h4>Tutorial:</h4>

      <IframeVimeo title="Planos e Sonhos (Ajuda)" videoId="768237530" />
    </div>

    <div>
      <h4>Tutorial Criar Planos:</h4>

      <p>Como criar Planos e Sonhos</p>

      <IframeVimeo title="Planos e Sonhos (Ajuda)" videoId="893890399" />
    </div>

    <div>
      <h4>Tutorial Edição:</h4>

      <p>Como editar plano e ver na gestão de planos</p>

      <IframeVimeo title="Planos e Sonhos (Ajuda)" videoId="893890476" />
    </div>
  </Pagination>
)

export const HelpGestaoPlanos = () => (
  <div>
    <p>
      Nesta tela você conseguirá visualizar os dados de todos os planos, bem como o valor total necessário que
      você deve investir mensalmente para alcançar os seus objetivos.
    </p>
    <p>
      Na gestão de sonhos você pode alterar os campos como taxa, prazo, valor presente, inflação e parcela.
      Faça o teste mudando os parâmetros e ajustando mais próximo da sua realidade.
    </p>
    <p>
      Tente chegar em um valor de parcela mensal próximo ao que você consegue investir para alcançar seus
      planos.
    </p>
    <p>
      O valor acumulado não pode ser alterado nesta tela, apenas na destinação de patrimônio, onde atribuímos
      o patrimônio acumulado aos seus planos.
    </p>
    <p>Ao salvar, os valores serão atualizados em nosso banco de dados.</p>

    <h4>Tutorial:</h4>

    <IframeVimeo title="Gestão de Planos (Ajuda)" videoId="768237777" />
  </div>
)

export const HelpDestinacaoPatrimonio = () => (
  <Pagination>
    <div>
      <p>
        Aqui você pode destinar um patrimônio a um objetivo específico. Como por exemplo atribuir uma
        previdência privada ao plano da aposentadoria, ou a venda de um imóvel para a compra de outra casa.
      </p>
      <p>
        O mais interessante é que você pode atribuir percentualmente um patrimônio para vários objetivos. Ex.
        Um fundo de investimentos pode ser 50% para uma viagem especial e outros 50% como uma reserva
        financeira.
      </p>
      <p>
        Alinhar seu patrimônio para os seus planos deixará você mais perto das suas conquistas e trará mais
        clareza para o objetivo do seu dinheiro.
      </p>
      <p>Os valores atribuídos serão somados ao patrimônio acumulado dos seus sonhos.</p>

      <h4>Tutorial:</h4>

      <IframeVimeo title="Destinação de Patrimônio (Ajuda)" videoId="893890449" />
    </div>

    <div>
      <h4>Tutorial como destinar patrimônio:</h4>
      <IframeVimeo title="Como destinar patrimônio (Ajuda)" videoId="893890399" />
    </div>
  </Pagination>
)

export const HelpPatrimonio = () => {
  return (
    <Pagination>
      <div>
        <p>
          Nesta tela você vai inserir todo o seu patrimônio, seja financeiro ou imobilizado. Aqui será toda a
          visão dos seus bens.
        </p>
        <p>
          Abaixo encontram-se alguns gráficos da evolução dos seus investimentos. Grande parte dos ativos
          financeiros estão vinculados com inteligência para atualizar automaticamente.
        </p>
        <p>
          Cadastre todos os seus investimentos financeiros. Quando fizer novos aportes, basta entrar em “Novo
          Lançamento” no topo da página, escolher a aba “Investimentos” e escolher o ativo que deseja
          investir. Automaticamente o sistema reconhecerá o seu aporte.
        </p>

        <h4>Tutorial (Patrimônio):</h4>

        <IframeVimeo title="Patrimônio (Ajuda)" videoId="768239284" />
      </div>

      <div>
        <h4>Tutorial (Introdução Geral):</h4>

        <IframeVimeo title="Patrimônio - Introdução Geral (Ajuda)" videoId="803298081" />
      </div>

      <div>
        <h4>Tutorial (Integração de Investimentos):</h4>

        <IframeVimeo
          title="Patrimônio - Integração de Investimentos (Ajuda)"
          videoId="https://youtu.be/vHemGaumC3I"
        />
      </div>

      <div>
        <h4>Tutorial (Debêntures):</h4>

        <IframeVimeo title="Patrimônio - Debêntures (Ajuda)" videoId="803297760" />
      </div>

      <div>
        <h4>Tutorial (Ações / FIIs / ETFs):</h4>

        <IframeVimeo title="Patrimônio - Ações / FIIs / ETFs (Ajuda)" videoId="803297349" />
      </div>

      <div>
        <h4>Tutorial (Moedas):</h4>

        <IframeVimeo title="Patrimônio - Moedas (Ajuda)" videoId="803298179" />
      </div>

      <div>
        <h4>Tutorial (Fundos e Previdência):</h4>

        <IframeVimeo title="Patrimônio - Fundos e Previdência (Ajuda)" videoId="803298179" />
      </div>

      <div>
        <h4>Tutorial (Produto Customizado):</h4>

        <IframeVimeo title="Patrimônio - Produto Customizado (Ajuda)" videoId="803298257" />
      </div>

      <div>
        <h4>Tutorial (Poupança):</h4>

        <IframeVimeo title="Patrimônio - Poupança (Ajuda)" videoId="803298219" />
      </div>

      <div>
        <h4>Tutorial (Renda Fixa Pré Fixado):</h4>

        <IframeVimeo title="Patrimônio - Renda Fixa Pré Fixado (Ajuda)" videoId="803298523" />
      </div>

      <div>
        <h4>Tutorial (Renda Fixa Pós Fixado):</h4>

        <IframeVimeo title="Patrimônio - Renda Fixa Pós Fixado (Ajuda)" videoId="803298351" />
      </div>

      <div>
        <h4>Tutorial (Tesouro Direto):</h4>

        <IframeVimeo title="Patrimônio - Tesouro Direto (Ajuda)" videoId="803298649" />
      </div>
    </Pagination>
  )
}

export const HelpDestinacaoInvestimentos = () => (
  <div>
    <p>
      Aqui você irá determinar para onde seus investimentos mensais serão destinados. Ao realizar o
      investimento do mês ele será distribuído na proporção que você determinar para cada um dos seus planos.
    </p>
    <p>
      Ex: Se você determinar 30% de um fundo de ações para a aposentadoria e outros 70% para a compra de uma
      casa, ao alocar qualquer valor neste fundo a inteligência do Vista distribuirá nesta proporção aos seus
      planos.
    </p>

    <h4>Tutorial:</h4>

    <IframeVimeo title="Destinação de Investimentos (Ajuda)" videoId="768239591" />
  </div>
)

export const HelpDividas = () => (
  <Pagination>
    <div>
      <p>O cadastro de dívidas te ajuda a organizar melhor os seus passivos financeiros.</p>
      <p>
        Poderão haver divergências dos valores da sua dívida real, uma vez que cada dívida tem peculiaridades,
        ajustes e características diversas. Portanto, atualizar os valores sempre que possível.
      </p>

      <h4>Tutorial:</h4>

      <IframeVimeo title="Dívidas (Ajuda)" videoId="768238916" />
    </div>

    <div>
      <h4>Tutorial Cadastro, edição e lançamento:</h4>

      <p>Como cadastrar, editar e lançar dívida no Vista</p>

      <IframeVimeo title="Dívidas (Ajuda)" videoId="893890362" />
    </div>
  </Pagination>
)

export const HelpGestaoOrcamento = () => (
  <div>
    <p>
      O orçamento é toda a estrutura do seu fluxo de caixa. Aqui você poderá criar e excluir categorias e
      determinar estimativas; bem como uma série de informações para sua análise. Clique em “x” e personalize
      suas informações que deseja enxergar.
    </p>

    <p>
      <strong>Orçamento</strong>: São centro de custos, como Adulto, Família, Imóvel, Carro, Investimentos.
      <br />
      <strong>Categoria</strong>: São os itens dentro de cada orçamento, como condomínio, aluguel, etc (No
      caso do orçamento Imóvel).
      <br />
      <strong>Estimado</strong>: É o valor você estima de receitas e despesas. Os valores de investimentos e
      dívidas são automáticos e não editáveis por aqui.
      <br />
      <strong>Meio de pagamento padrão</strong>: escolha os meios de pagamento que você utiliza para facilitar
      nos seus lançamentos.
      <br />
      <strong>Quantidade</strong>: Quantos gastos houveram nesta categoria.
    </p>

    <p>
      <strong>Frequência</strong>: Poderá ser mensal (frequente) ou anual (gastos esporádicos). O orçamento
      também é uma ferramenta de análise do seu fluxo de caixa; classifique e veja os seus maiores gastos,
      qual é a média, as estimativas, etc.
    </p>

    <h4>Tutorial Gestão de Orçamento</h4>
    <IframeVimeo title="Gestão de Orçamento (Ajuda)" videoId="768238035" />
  </div>
)

export const HelpExtrato = () => (
  <Pagination>
    <div>
      <p>
        O extrato te dará uma visão de todos os seus lançamentos. Por aqui também será possível editar e
        apagar seus lançamentos.
      </p>

      <p>Veja quais os seus gastos ou receitas estão parcelados.</p>

      <h4>Tutorial Categorização em Lote</h4>
      <IframeVimeo title="Extrato (Ajuda)" videoId="810327447" />
    </div>

    <div>
      <h4>Tutorial Upload de Extrato Vista</h4>
      <IframeVimeo title="Tutorial Upload de Extrato Vista (Ajuda)" videoId="728264539" />
    </div>

    <div>
      <h4>Como utilizar o Excel padrão Vista</h4>
      <IframeVimeo title="Como utilizar o Excel padrão Vista (Ajuda)" videoId="893802221" />
    </div>

    <div>
      <h4>Tutorial Excel Padrão Vista</h4>
      <IframeVimeo title="Tutorial Excel Padrão Vista (Ajuda)" videoId="814246422" />
    </div>

    <div>
      <h4>Tutorial Categorização Automatica dos Lançamentos</h4>
      <IframeVimeo
        title="Tutorial Categorização Automatica dos Lançamentos (Ajuda)"
        videoId="https://youtu.be/xrEMQ7G7D9o"
      />
    </div>
  </Pagination>
)

export const HelpEquilibrioFinanceiro = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <p>
      Determine metas e objetivos gerais, veja como está a sua evolução e quanto dinheiro está economizando e
      ganhando.
    </p>
  </div>
)

export const HelpMeioPagamento = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <p>
      Determine metas e objetivos gerais, veja como está a sua evolução e quanto dinheiro está economizando e
      ganhando.
    </p>

    <IframeVimeo title="Meio de pagamento (Ajuda)" videoId="893890362" />
  </div>
)

export const HelpIndependenciaFinanceira = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <h4>Tutorial</h4>
    <IframeVimeo title="Tutorial Independência Financeira (Ajuda)" videoId="768237876" />
  </div>
)

export const HelpIntegracao = () => (
  <div>
    <h3>Bem vindo ao Open Finance do Vista!</h3>

    <p>
      Aqui você ira conectar suas contas bancárias para obtermos as informações das suas movimentações. O
      acesso é apenas às informações e não é possível executar nenhuma outra atividade em suas contas.
      Respeitamos a sua privacidade e dados. Todas as informações são criptografadas, assim como nos bancos os
      seus dados não são (e nunca serão) compartilhados ou vendidos à terceiros.
    </p>

    <p>
      Uma das partes mais importantes do planejamento financeiro, porém que demanda um certo tempo, é anotar
      suas receitas e despesas. Nós iremos facilitar isso para você!
    </p>

    <p>
      Depois que você conectar as suas contas, você precisará vincular os meios de pagamento do seu banco com
      aqueles cadastrados no Vista. Para isso, acesse a aba “integrações”, encontre a integração conectada e
      faça a destinação.
    </p>

    <p>
      Depois de conectar as suas contas e vincular aos meios de pagamento, vamos buscar todas as transações de
      cartão de crédito e conta corrente a partir de hoje, mas você terá que categorizar (identificar) estas
      movimentações.
    </p>

    <p>
      Para isso, entre no seu extrato e categorize suas movimentações, ou seja, determine o “orçamento” e
      “categoria” mais adequado para esta movimentação. Todas as movimentações podem ser editadas (inclusive
      excluídas).
    </p>

    <p>
      Caso você receba uma mensagem do seu banco informando sobre o nosso acesso, não se preocupe, é uma
      mensagem padrão e de segurança de alguns bancos. Caso tenha qualquer dúvida, entre em contato com o seu
      planejador financeiro. Bom planejamento!!!
    </p>

    <p>
      Aqui seus dados estão seguros e queremos te ajudar ao máximo para facilitar o seu planejamento
      financeiro.
    </p>
  </div>
)

const HelpInvestments = () => (
  <div>
    <h4>Tutorial (Integração de Investimentos):</h4>

    <IframeVimeo
      title="Patrimônio - Integração de Investimentos (Ajuda)"
      videoId="https://youtu.be/vHemGaumC3I"
    />
  </div>
)

const HelpPatrimonioIntegracao = () => (
  <>
    <Pagination>
      <div title="Como cadastrar um patrimônio de investimentos manuais">
        <h2>Como cadastrar um patrimônio de investimentos manuais</h2>
        <br />
        <IframeVimeo title="Como cadastrar um patrimônio de investimentos manuais" videoId="960489896" />
      </div>

      <div title="Como fazer aplicações e resgates de investimentos manuais">
        <h2>Como fazer aplicações e resgates de investimentos manuais</h2>
        <br />
        <IframeVimeo title="Como fazer aplicações e resgates de investimentos manuais" videoId="960489967" />
      </div>

      <div title="Como atualizar o valor atual dos investimentos - para considerar a rentabilidade">
        <h2>Como atualizar o valor atual dos investimentos - para considerar a rentabilidade</h2>
        <br />
        <IframeVimeo
          title="Como atualizar o valor atual dos investimentos - para considerar a rentabilidade"
          videoId="960490061"
        />
      </div>

      <div title="Como destinar patrimônio de investimentos manuais - tela de destinação de patrimônio">
        <h2>Como destinar patrimônio de investimentos manuais - tela de destinação de patrimônio</h2>
        <br />
        <IframeVimeo
          title="Como destinar patrimônio de investimentos manuais - tela de destinação de patrimônio"
          videoId="960490129"
        />
      </div>
    </Pagination>
  </>
)

export const MAP: MapProps = {
  '/home': HelpHome,
  '/planos-sonhos': HelpPlanosSonhos,
  '/gestao-planos-sonhos': HelpGestaoPlanos,
  '/destinacao-patrimonio': HelpDestinacaoPatrimonio,
  '/patrimonio': HelpPatrimonio,
  '/destinacao-investimentos': HelpDestinacaoInvestimentos,
  '/dividas': HelpDividas,
  '/orcamento': HelpGestaoOrcamento,
  '/extrato': HelpExtrato,
  '/equilibrio-financeiro': HelpEquilibrioFinanceiro,
  '/independencia-financeira': HelpIndependenciaFinanceira,
  '/integracoes': HelpIntegracao,
  '/investimentos': HelpInvestments,
  '/meios-pagamento': HelpMeioPagamento,
  '/patrimonio-integracao': HelpPatrimonioIntegracao
}
