import React, { useState } from 'react'
import { useTheme } from '../../../hooks'
import { useThemesList } from '../../../queries/theme/queries'
import Select from '../Select'
import styled from 'styled-components'

const Container = styled.div`
  width: 25vw;
  height: 6vh;
`

export default () => {
  const { changeTheme } = useTheme()
  const [filter, setFilter] = useState('')
  const { themes, isLoadingThemesList, isThemesListError } = useThemesList()
  const options = themes?.map((theme) => ({ text: theme.replace('theme-', ''), value: theme })) ?? []
  const filteredOptions = options.filter(({ text }) => text.includes(filter))

  if (isThemesListError) return <>Erro ao listar temas :c</>

  return (
    <Container>
      <Select
        filter={filter}
        options={filteredOptions}
        onSelection={changeTheme}
        onFilter={setFilter}
        placeholder="Selecione um tema"
        loading={isLoadingThemesList}
      />
    </Container>
  )
}
