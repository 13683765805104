import {
  CheckCircle as CheckCircleIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons'
import styled, { css } from 'styled-components'
import colors from '../../../domain/Colors'

export const IsManager = styled(CheckCircleIcon)`
  ${() => css`
    color: ${colors.iconSuccess};
  `}
`
export const NotIsManager = styled(HighlightOffIcon)`
  ${() => css`
    color: ${colors.iconDefault};
  `}
`
