import styled, { css } from 'styled-components'
import { FooterRow } from '../Footer/Footer.styles'

export const ErrorContainer = styled('div')`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: 'flex-start';
    width: fit-content;
  `}
`

export const ExpandedContainer = styled('div')`
  ${() => css`
    padding: 20px;
    width: 100%;
  `}
`

export const Footer = styled(FooterRow)`
  ${() => css`
    text-align: center;

    span:first-child {
      width: 64px;
    }

    span + span {
      flex: 1;
    }
  `}
`

export const SaveArea = styled('div')`
  ${() => css`
    width: 100%;
    padding: 0 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;

    button + div {
      height: 40px;
    }
  `}
`

export const CloseButton = styled('button')`
  ${() => css`
    border: none;
    background: #a0a0a016;
    height: 40px;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    border-radius: 24px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #a0a0a040;
    }
  `}
`

interface IconSizer {
  pad?: string
}
export const IconSizer = styled('div')<IconSizer>`
  ${({ pad }) => css`
    width: 10px;
    height: 40px;
    padding: ${() => pad || '0px'};
  `}
`
