import React, { useMemo } from 'react'
import Select from '../../../Selects'
import { Selectable } from '../../../Selects/Select/Options'
import { ColumnFilterSelect } from '../columnNamesAndFilters'

export default ({ currentSelected, onChange }: ColumnFilterSelect) => {
  const tipoOrcamentos: Selectable<string>[] = useMemo(
    () => [
      { value: 'Receita', text: 'Receita' },
      { value: 'Despesa', text: 'Despesa' },
      { value: 'Investimento', text: 'Investimento' },
      { value: 'Divida', text: 'Divida' },
    ],
    []
  )
  const placeholder =
    tipoOrcamentos.find(({ value }) => value === currentSelected)?.text || 'Nenhum'

  return <Select placeholder={placeholder} options={tipoOrcamentos} onSelection={onChange} />
}
