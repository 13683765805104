import { cacheKey } from './queries'
import { cacheBaseKey as transactionsCacheKey } from '../lancamento/queries'
import { useMutation, useQueryClient } from 'react-query'
import {
  fetchPatrimonioByFamilyIdApi,
  fetchPatrimonioFinancialInstitutionsApi
} from '../../domain/patrimonio'
// import { useLocalStorage } from '../../hooks'
// import { useEffect } from 'react'

export const useFetchPatrimonioByFamilyId = () => {
  const client = useQueryClient()
  // const { setValue: setStore, value } = useLocalStorage('store')
  // const store = value ? JSON.parse(value) : ''

  const {
    mutate: fetchPatrimonioByFamilyId,
    mutateAsync: fetchPatrimonioByFamilyIdAsync,
    isLoading: isLoadingPatrimonioByFamilyId,
    isError: isErrorPatrimonioByFamilyId,
    error: PatrimonioByFamilyError,
    isSuccess: isSuccessPatrimonioByFamilyId,
    reset: resetFetchPatrimonioByFamilyId,
    data
  } = useMutation(fetchPatrimonioByFamilyIdApi, {
    retry: false,
    onSuccess: () => {
      client.invalidateQueries(cacheKey)
      client.invalidateQueries(transactionsCacheKey)
    }
  })

  const { patrimonios, totalResults } = data || {}
  const patrimoniosByFamilyId = patrimonios || []

  // useEffect(() => {
  //   if (patrimoniosByFamilyId) {
  //     setStore(JSON.stringify({ ...store, patrimoniosByFamilyId }))
  //   }
  // }, [patrimoniosByFamilyId, setStore, store])

  return {
    fetchPatrimonioByFamilyId,
    fetchPatrimonioByFamilyIdAsync,
    isLoadingPatrimonioByFamilyId,
    isErrorPatrimonioByFamilyId,
    PatrimonioByFamilyError,
    isSuccessPatrimonioByFamilyId,
    resetFetchPatrimonioByFamilyId,
    patrimoniosByFamilyId,
    totalResults
  }
}

export const useFetchPatrimonioFinancialInstitutions = () => {
  const client = useQueryClient()

  const {
    mutate: fetchPatrimonioFinancialInstitutions,
    mutateAsync: fetchPatrimonioFinancialInstitutionsAsync,
    isLoading: isLoadingPatrimonioFinancialInstitutions,
    isError: isErrorPatrimonioFinancialInstitutions,
    error: PatrimonioByFamilyError,
    isSuccess: isSuccessPatrimonioFinancialInstitutions,
    reset: resetFetchPatrimonioFinancialInstitutions,
    data
  } = useMutation(fetchPatrimonioFinancialInstitutionsApi, {
    retry: false,
    onSuccess: () => {
      client.invalidateQueries(cacheKey)
      client.invalidateQueries(transactionsCacheKey)
    }
  })

  const { financialInstitutions } = data || {}
  const totalResults = financialInstitutions?.length

  return {
    fetchPatrimonioFinancialInstitutions,
    fetchPatrimonioFinancialInstitutionsAsync,
    isLoadingPatrimonioFinancialInstitutions,
    isErrorPatrimonioFinancialInstitutions,
    PatrimonioByFamilyError,
    isSuccessPatrimonioFinancialInstitutions,
    resetFetchPatrimonioFinancialInstitutions,
    financialInstitutions,
    totalResults
  }
}
