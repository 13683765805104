export type FamilyBilling = {
  id: number
  name: string
  active: boolean
  investors: number
  creation: string // 2022-01-17T15:01:12 format
  lastUpdated: string // 2022-01-17T15:01:12 format
}

export const getBillingsByPlannerIdLoading = (): FamilyBilling => ({
  active: true,
  creation: new Date().toISOString(),
  id: 0,
  investors: 0,
  lastUpdated: new Date().toISOString(),
  name: '--- --- --- --- ---',
})
