import React from 'react'
import useKonami from 'use-konami'
import { useBooleanLocalStorage } from '../../hooks'

export interface BetaUserProps {
  betaUser: boolean
}

export const withBetaUserEasterEgg =
  (Component: (props: BetaUserProps) => JSX.Element, sequence: string[]) =>
  (props: BetaUserProps) => {
    const { value: betaUser, setValue: setBetaUser } = useBooleanLocalStorage('betaUser', false)
    useKonami({ onUnlock: () => setBetaUser(!betaUser), sequence })
    return <Component {...props} betaUser={betaUser} />
  }
