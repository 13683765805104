import { showAlert } from '../../../hooks'
import { noticeError } from '../../../services/Monitoring'

export const validateManualInvestment = ({ type, investmentType, name }) => {
  const isManualInvestment = type === 'Investimentos'
  let isValid = true

  if (!isManualInvestment) return { isValid }
  if (!type || !type || !name || !investmentType) isValid = false
  if (name && name.length < 3) isValid = false

  return {
    isValid
  }
}

export const validateFixedAsset = ({ type, familyId, value, buyDate, name }) => {
  const isManualInvestment = type === 'Investimentos'
  let isValid = true

  if (isManualInvestment) return { isValid }
  if (!familyId || !type || !name || !value || !buyDate) isValid = false
  if (name && name.length < 3) isValid = false

  return {
    isValid
  }
}

export const saveManualInvestment = async ({
  fixedAsset,
  currentValues,
  onSave,
  method,
  updateMethod,
  isEditing
}) => {
  if (isEditing) {
    const { id, isManual: isManualInvestment } = fixedAsset
    const { vistaType, value: amount, balance: amountOriginal, name: description } = currentValues
    const newValues = { id, vistaType, amount, amountOriginal, description, isManualInvestment }
    //   vistaType?: ManualInvestmentType // tipo investimento
    // description?: string // descricao
    // amount?: number // valor atual
    // amountOriginal
    // const assetWithId = {
    //   ...newValues,
    //   _id: fixedAsset._id,
    //   id: fixedAsset.id,
    //   isManualInvestment: fixedAsset.isManual
    // }
    updateMethod(newValues)
    onSave(newValues)
    return
  }
  const { userId, familyId, investmentType, name: description, balance, value } = currentValues
  const newValues = { userId, familyId, investmentType, description, balance, value }
  const asset = await method(newValues)
  onSave(asset)
}

export const saveFixedAsset = async ({ fixedAsset, currentValues, onSave, method, updateMethod }) => {
  const { familyId, type, name, value, buyDate } = currentValues
  const newValues = { familyId, type, name, value, buyDate }

  if (fixedAsset) {
    const assetWithId = { ...newValues, _id: fixedAsset._id }
    await updateMethod(assetWithId)
    onSave(assetWithId)
    return
  }

  const asset = await method(newValues)
  onSave(asset)
}

export const showError = ({ type, error, currentValues, onClose, reset }) => {
  noticeError(error, currentValues)
  showAlert(
    `Erro ao ${type} patrimônio`,
    `Houve um erro interno ao ${type} o patrimônio. Por favor tente novamente mais tarde.`,
    () => {
      reset()
      onClose()
    }
  )
}
