import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { WrapperForModal } from './styles'
import { TitleModal } from './Title'
import { FormReceita } from './formReceita'
import { FormDespesa } from './formDespesa'
import { FormManualInvestment } from './FormManualInvestment'
import { FormDivida } from './formDivida'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { TABS, TTabs } from '../SaveDataTransaction/types'
import { StyledSkyLight } from './styles'
import { FormInvestimento } from './formInvestment'
import { useLocalStorage } from '../../../../../hooks'
import SkyLight from 'react-skylight'

export const ModalNewTransaction = ({ parentThis }) => {
  const { modal, transaction } = useTransactionContext()
  const { EditingModals } = useSaveTransactionsActions()
  const { removeValue: removeEntryValue } = useLocalStorage('lancamentoEdicao')
  const { state } = parentThis
  const item = transaction
  const selectedTab = TABS[item?.tipoOrcamento ?? 1]
  const modalRef = useRef<SkyLight>(null)
  const [activeTab, setActiveTab] = useState<TTabs>(selectedTab)

  const isEditingExtractUpload = useMemo(
    () => !!item?.id && !item?.isManual && !item?.pluggyInvestmentId,
    [item]
  )

  const FormList = useMemo(
    () => ({
      [TABS[0]]: <FormReceita parentThis={parentThis} />,
      [TABS[1]]: <FormDespesa parentThis={parentThis} />,
      [TABS[2]]: isEditingExtractUpload ? (
        <FormInvestimento parentThis={parentThis} />
      ) : (
        <FormManualInvestment />
      ),
      [TABS[3]]: <FormDivida />
    }),
    [isEditingExtractUpload, parentThis]
  )

  const Title = useCallback(() => {
    if (!activeTab) return <></>
    return <TitleModal setActiveTab={setActiveTab} activeTab={activeTab} item={item} state={state} />
  }, [activeTab, item, state])

  useEffect(() => {
    if (modal?.open) {
      if (modalRef?.current && !modalRef?.current?.state?.isVisible) modalRef?.current?.show()
      return
    }

    if (!modal?.open && modalRef?.current?.state?.isVisible) {
      modalRef?.current?.hide()
      // modal.close()
    }
  }, [item, modal, modalRef])

  useEffect(() => {
    setActiveTab(selectedTab)
  }, [selectedTab])

  if (!modal?.open) return <></>

  return (
    <>
      <WrapperForModal open={modal?.open}>
        <StyledSkyLight
          ref={modalRef}
          // transitionDuration={200}
          beforeClose={() => {
            modal.close()
            removeEntryValue()
          }}
          dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
          title={<Title />}
        >
          {activeTab && FormList[activeTab]}
        </StyledSkyLight>
      </WrapperForModal>

      <EditingModals />
    </>
  )
}
