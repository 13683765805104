import Table from './Table'
import ConnectionsTable from './ConnectionsTable'
import LancamentosTable from './LancamentosTable'
import PlannerBillingTable from './BillingsByPlannerIdTable'
import ManagerBillingTable from './PlannersByCompanyIdTable'
import PlannersTable from './PlannersTable'
import FixedAssetsTable from './fixedAssetsTable'
import { CompaniesTable } from './CompaniesTable'
import { LonelyPlannersTable } from './LonelyPlannersTable'
import { InvestmentTypesByUserIdTable } from './InvestmentTypesByUserIdTable'
import { TransactionsByInvestmentIdTable } from './TransactionsByInvestmentIdTable'
import { InvestmentsByItemIdTable } from './InvestmentsByItemIdTable'
import { EquityInvestmentsByUserIdTable } from './EquityInvestmentsByUserIdTable'
export default Table

export {
  CompaniesTable,
  ConnectionsTable,
  FixedAssetsTable,
  LancamentosTable,
  LonelyPlannersTable,
  ManagerBillingTable,
  PlannerBillingTable,
  PlannersTable,
  InvestmentTypesByUserIdTable,
  TransactionsByInvestmentIdTable,
  InvestmentsByItemIdTable,
  EquityInvestmentsByUserIdTable
}
