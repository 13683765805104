import React from 'react'
import { useBooleanLocalStorage } from '../../hooks/useLocalStorage'
import Modal from './Modal'
import styled from 'styled-components'
import { Checkbox } from '../Inputs'

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 100vh;
`

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  a {
    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.colors.secondary};
    text-decoration: none;
    padding: 2px;
    margin: 2px;
    font-weight: 700;
    &:hover {
      background: #64646416;
    }
  }
`

// const Row = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `

// const PaddedText = styled.span`
//   margin-left: 10px;
// `

const Terms = styled.p`
  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 10px;

    &:hover,
    &:focus {
      font-weight: bold;
    }
  }
`

export default () => {
  const { value: isAccepted, setValue: setIsAccepted } = useBooleanLocalStorage('modalprimo', false)
  return (
    <Modal open={!isAccepted} closeable={false} hideBackground>
      <Content>
        <Title>
          <span role="img" aria-label="🎉">
            🎉
          </span>
          Novidade no Meu Vista: Conheça o "Resumo Vista"!
          <span role="img" aria-label="📊">
            📊
          </span>
        </Title>
        <br />
        <Text>
          Caros clientes do Meu Vista,
          <br /> <br />É com grande entusiasmo que apresentamos a vocês o mais novo recurso do nosso sistema:
          o "Resumo Vista"! Fruto do pedido do influenciador e sócio do Vista, Thiago Nigro, esta ferramenta
          revolucionária está pronta para revolucionar seu controle financeiro.
          <br /> <br />
          O "Resumo Vista" vai além de uma simples tela; é uma poderosa ferramenta de análise abrangente.
          Tenha uma visão completa das suas receitas, despesas, investimentos e dívidas, tudo de maneira clara
          e intuitiva. Gráficos comparativos ajudarão você a compreender seus dados de forma ainda mais
          reveladora.
          <br /> <br />
          Tenha o controle total das suas finanças ao longo do ano, permitindo decisões mais estratégicas para
          alcançar seus objetivos financeiros.
          <br /> <br />
          E isso não é tudo! Em breve, você poderá exportar o "Resumo Vista" como um relatório detalhado.
          Compartilhe suas conquistas e metas com facilidade, reforçando seu planejamento financeiro.
          <br /> <br />
          No Meu Vista, nossa missão é fornecer as melhores ferramentas para sua independência financeira, e o
          "Resumo Vista" é um grande passo nessa jornada. Agradecemos por fazerem parte dela e esperamos que
          esta funcionalidade torne o controle financeiro ainda mais prazeroso e eficiente.
          <br /> <br />
          Explore o "Resumo Vista" agora e veja como o poder dos números pode transformar suas finanças!
          <br /> <br />
          Atenciosamente,
          <br />
          Equipe Meu Vista
        </Text>

        <Terms>
          <label>
            <Checkbox onChange={(checked) => setIsAccepted(checked)} checked={isAccepted} />
            <span>Li e aceito</span>
          </label>
        </Terms>
      </Content>
    </Modal>
  )
}
