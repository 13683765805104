import React, { useContext, useEffect } from 'react'
import { ThemeContext, AuthContext, useLocalStorage } from '../../hooks'
import { ThemeContextValue } from '../../hooks/contexts/themeContext'
import { AuthContextValue } from '../../hooks/contexts/authContext'
import { useCoreContext } from '../../hooks/contexts/coreProvider'
import { useFamilyQuery, useGetImageBYFamilyId } from '../../queries/family/queries'
import { Family } from '../../domain/family/Family'

interface ComponentProps {
  authContext?: AuthContextValue
  authenticated?: boolean
  empresaId?: number | null
  familiaId?: number | null
  family?: Family
  familyImage?: string
  setEmpresaId: React.Dispatch<React.SetStateAction<string | null>>
  setFamiliaId: React.Dispatch<React.SetStateAction<string | null>>
  tipoUsuario?: number | null
  themeContext?: ThemeContextValue
  planejadorId?: number | null
}

export const withContexts =
  (Component: (props: ComponentProps) => JSX.Element) => (props: ComponentProps) => {
    const themeContext = useContext(ThemeContext)
    const authContext = useContext(AuthContext)
    const { token } = authContext || {}
    const { authenticated } = useCoreContext()
    const { setValue: setEmpresaId, value: empresaId } = useLocalStorage('empresa-id')
    const { setValue: setFamiliaId, value: familyId } = useLocalStorage('familia-id')
    const { value: type } = useLocalStorage('tipo-usuario')
    const { setValue: setPlanejadorId, value: planejadorId } = useLocalStorage('planejador-id')
    const { family } = useFamilyQuery(familyId ? parseInt(familyId) : undefined)
    const { familyImage } = useGetImageBYFamilyId({ familyId: familyId })
    const tipoUsuario = type ? parseInt(type) : token?.type

    useEffect(() => {
      if (authenticated) {
        if (family?.id || token?.familiaId) setFamiliaId(String(family?.id ?? token?.familiaId))
        if (family?.empresaId || token?.empresaId) setEmpresaId(String(family?.empresaId ?? token?.empresaId))
        if (family?.planejadorId || token?.planejadorId)
          setPlanejadorId(String(family?.planejadorId ?? token?.planejadorId))
      } else {
        setFamiliaId(null)
        setEmpresaId(null)
        setPlanejadorId(null)
      }
    }, [authenticated, family, setEmpresaId, setFamiliaId, setPlanejadorId, token])

    return (
      <Component
        {...props}
        authContext={authContext}
        authenticated={authenticated}
        empresaId={empresaId ? Number(empresaId) : null}
        family={family ?? ({} as Family)}
        familiaId={familyId ? Number(familyId) : null}
        familyImage={familyImage}
        tipoUsuario={typeof tipoUsuario === 'number' ? tipoUsuario : null}
        setEmpresaId={setEmpresaId}
        setFamiliaId={setFamiliaId}
        planejadorId={planejadorId ? Number(planejadorId) : null}
        themeContext={themeContext}
      />
    )
  }
