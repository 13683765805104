import Modal from './Modal'
import InitialModal from './InitialModal'
import ChoicesModal, { BankIntegrationDaysModal } from './ChoicesModal'
import ThemesModal from './ThemesModal'
import SaveButtonModal from './SaveButtonModal'
import FixedAssetModal from './FixedAssetModal'
import { NewModal } from './NewModal'
import { HelpModal } from './HelpModal'
import {
  AlertModal,
  DeleteBudgetModal,
  InvestmentNameConfirmModal,
  DeleteInvestmentModal,
  InvestmentValueConfirmModal
} from './alertModal'

export default Modal
export {
  AlertModal,
  BankIntegrationDaysModal,
  ChoicesModal,
  DeleteBudgetModal,
  DeleteInvestmentModal,
  FixedAssetModal,
  HelpModal,
  InitialModal,
  InvestmentNameConfirmModal,
  InvestmentValueConfirmModal,
  NewModal,
  SaveButtonModal,
  ThemesModal
}
