import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Card, CardBody, FormGroup, Form, Label, Input, InputGroup, Row, Col } from 'reactstrap'
import Logo from '../../theme/logo'
import { authenticate } from '../../domain/auth'
import showAlert from '../../hooks/useShowAlert'
import RelationsThemeDomains from '../../theme/relationsThemeDomains.json'
import { withContexts } from '../../components'
import { resetEssentialsLocalStorage } from '../../components/AuthRoute/utils'
import Button from '../../components/Buttons'

const processRedirectUrl = ({ redirect }) => {
  let redirectUrl = window.location.pathname.split('to/')[1]

  if (redirect && redirectUrl) {
    redirectUrl = `/${redirectUrl.replace('/v1', '')}`
  }

  return redirectUrl
}

class Login extends React.Component {
  async componentDidMount() {
    await resetEssentialsLocalStorage()

    document.addEventListener('keypress', this.handleKeyPress)

    if (global.apiToken === '') {
      global.getCredential()
    }
    const currentDomain = window.location.href.split('/')[2]
    for (const themeDomain in RelationsThemeDomains)
      if (currentDomain === themeDomain)
        this.props.themeContext.changeTheme(`theme-${RelationsThemeDomains[themeDomain]}`)
  }

  state = {
    email: null,
    senha: null,
    redirect: false,
    hideRegisterLink: true,
    isSubmitting: false
  }

  authenticate = async () => {
    this.setState({ isSubmitting: true })
    try {
      const { email, senha: password } = this.state
      const { token, jwt } = await authenticate({ email, password })

      this.props.authContext.setJwt(jwt)
      localStorage.setItem('jwt', jwt)

      // console.log({ jwt, token })
      // Os dados só são salvos no localStorage por retrocompatibilidade
      // com os componentes antigos. Os novos devem usar o authContext.
      localStorage.setItem('usuario-email', token.email)
      localStorage.setItem('planejador-principal', '1')
      localStorage.setItem('tipo-usuario', token.type)
      localStorage.setItem('theme', token.theme)

      if (token.theme) localStorage.setItem('theme', token.theme)
      if (token.id) localStorage.setItem('usuario-id', token.id)
      if (token.pessoaId) localStorage.setItem('pessoa-id', token.pessoaId)
      if (token.empresaId) localStorage.setItem('empresa-id', token.empresaId)
      if (token.familiaId) localStorage.setItem('familia-id', token.familiaId)
      if (token.planejadorId) localStorage.setItem('planejador-id', token.planejadorId)
      if (token.theme) this.props.themeContext.changeTheme(token.theme)

      this.setState({ redirect: true })
    } catch (err) {
      this.setState({ isSubmitting: false })
      if (err instanceof Error && err.message === 'invalidCredentials')
        showAlert('Falha no Login', 'Usuário ou senha inválidos.')
      else throw err
    }
  }

  render() {
    const { redirect } = this.state
    const redirectUrl = processRedirectUrl({ redirect })

    const input = {
      borderRadius: 25,
      paddingLeft: 20,
      border: 'none',
      backgroundColor: '#f7faff'
    }

    const label = {
      marginLeft: 15,
      fontSize: '0.9rem',
      fontWeight: 600
    }

    // console.log('login', { redirect }, this.props.authenticated)
    if (redirect && this.props.authenticated) {
      return <Redirect to={redirectUrl ? `${redirectUrl}` : '/admin/home'} />
    }

    return (
      <>
        <Col
          lg="6"
          md="12"
          style={{
            backgroundColor:
              this.props.themeContext.theme.colors.backgroundLogin ||
              this.props.themeContext.theme.colors.primary,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            minHeight: 240
          }}
        >
          <a
            href="https://meuvista.com/"
            target="_blank"
            rel="noreferrer noopener"
            style={{
              textAlign: 'center',
              maxWidth:
                this.props.themeContext.theme.login?.imageSize?.x !== undefined &&
                this.props.themeContext.theme.login?.imageSize?.x !== ''
                  ? this.props.themeContext.theme.login?.imageSize?.x
                  : '480px'
            }}
          >
            <Logo
              theme={this.props.themeContext.theme}
              sizeW={
                this.props.themeContext.theme.login?.imageSize?.x !== undefined &&
                this.props.themeContext.theme.login?.imageSize?.x !== ''
                  ? this.props.themeContext.theme.login?.imageSize?.x
                  : '100%'
              }
            />
          </a>
          <div style={{ position: 'absolute', bottom: 0, width: '100%' }} className="pb-3 mt-3">
            <div className="copyright text-center text-muted">
              © 2020 Todos os direitos reservados
              <span className="font-weight-bold ml-1">Vista</span>
            </div>
          </div>
        </Col>
        <Col
          lg="6"
          md="12"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#f7faff',
            padding: '30px 0px 30px 0px'
          }}
        >
          {this.props.themeContext.theme.isWhiteLabel || this.state.hideRegisterLink ? (
            ''
          ) : (
            <Row className="mt-3">
              <Link to="/auth/registrar" className="alert-link mb-3" style={{ color: '#9947F4' }}>
                Cadastro exclusivo para planejadores financeiros
              </Link>
            </Row>
          )}

          <Card
            className="bg-secondary shadow border-0"
            style={{ width: '90%', borderRadius: 15, maxWidth: 500 }}
          >
            <CardBody className="px-lg-5 py-lg-5" style={{ backgroundColor: '#FFF', borderRadius: 15 }}>
              <div className="text-center text-muted mb-4">
                <h2 style={{ fontWeight: 700 }}>{this.props.themeContext.theme.login.cardTitleText}</h2>
              </div>

              <Form role="form">
                <FormGroup className="mb-3">
                  <Label for="email" style={label}>
                    E-mail
                  </Label>
                  <InputGroup>
                    <Input
                      type="email"
                      value={this.state.email ?? ''}
                      onChange={(e) => this.setState({ email: e.target.value.trim() })}
                      onKeyPress={({ key }) => {
                        if (key === 'Enter') this.authenticate()
                      }}
                      style={input}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="senha" style={label}>
                    Senha
                  </Label>
                  <InputGroup>
                    <Input
                      type="password"
                      value={this.state.senha ?? ''}
                      onChange={(e) => this.setState({ senha: e.target.value.trim() })}
                      onKeyPress={({ key }) => {
                        if (key === 'Enter') this.authenticate()
                      }}
                      style={input}
                    />
                  </InputGroup>
                  <Row>
                    <Col className="text-right mt-2">
                      <Link to="/auth/reset" style={{ fontSize: '0.9rem', fontWeight: 600 }}>
                        Esqueci minha senha
                      </Link>
                    </Col>
                  </Row>
                </FormGroup>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Button
                    className="featured-button my-4"
                    color="primary"
                    type="button"
                    onKeyPress={this.handleKeyPress}
                    disabled={this.state.isSubmitting}
                    style={{
                      background:
                        `linear-gradient(45deg, ${this.props.themeContext.theme.colors.primary} 0%, ` +
                        `${this.props.themeContext.theme.colors.secondary} 100%)`,
                      minWidth: 150,
                      textTransform: 'uppercase',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={() => this.authenticate()}
                    text={this.state.isSubmitting ? 'Entrando...' : 'Entrar'}
                  />
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}

export default withContexts(Login)
