import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { IsManager, NotIsManager } from './LonelyPlannersTable.styles'
import { formatMonetaryValue } from '../../../domain/formatter'
import { LonelyPlanners } from '../../../domain/planner/planner'

export const useColumns = () => {
  const columns = useMemo(
    (): Column<LonelyPlanners>[] => [
      {
        Header: 'Nome',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        id: 'email',
        accessor: 'email',
        width: 400,
      },
      {
        Header: 'Documento',
        id: 'document',
        accessor: 'document',
      },
      {
        Header: 'Empresa',
        id: 'companyName',
        accessor: 'companyName',
        Cell: ({ value }) => (value ? value : '- - -'),
        width: 250,
      },
      {
        Header: 'CNPJ',
        id: 'companyDocument',
        accessor: 'companyDocument',
        Cell: ({ value }) => (value ? value : '- - -'),
      },
      {
        Header: 'Clientes',
        id: 'quantityOfFamilies',
        accessor: 'quantityOfFamilies',
      },
      {
        Header: 'Fatura',
        id: 'totalInvoice',
        accessor: 'totalInvoice',
        Cell: ({ value }) => `R$ ${formatMonetaryValue(value)}`,
      },
      {
        Header: 'Gestor',
        id: 'manager',
        accessor: 'manager',
        Cell: ({ value }) => {
          return <>{value ? <IsManager /> : <NotIsManager />}</>
        },
      },
    ],
    []
  )
  return columns
}
