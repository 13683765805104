import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Row, Col } from 'reactstrap'
import { InputQuantity } from '../../../../Inputs/InputQuantidade'
import { getTransactionType, TRANSACTION_NAME_CODE } from '../../../../../domain/lancamento/lancamento'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { useSelectComboContext } from '../../../../../hooks/contexts/selectComboContext'
import { FieldSelectOrcamento } from '../../../../Selects/FieldSelectOrcamento'
import { TipoOrcamento } from '../../../../../domain/orcamento/Orcamento'
import { FieldSelectCategoria } from '../../../../Selects/FieldSelectCategoria'
import { FieldSelectPaymentMethods } from '../../../../Selects/FieldSelectPaymentMethods'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { MeioPagamento, TIPO_ORCAMENTO_NAME_CODE, Transaction } from '../SaveDataTransaction/types'
import { FieldSelectDate } from '../../../../Selects/FieldSelectDate'
import moment from 'moment'
import { FieldInputAmount } from '../../../../Inputs/InputAmount'

export const FormDespesa = ({ parentThis }) => {
  const { SaveButton } = useSaveTransactionsActions()
  const { transaction, setTransaction } = useTransactionContext()
  const [item, setStateItem] = useState<Transaction | undefined>(transaction)
  const { MEIOS_PAGAMENTOS_OPTIONS } = useSelectComboContext()
  const { state } = parentThis
  const isEditing = !!transaction?.id
  const [validForm, setValidForm] = useState(false)
  const { isParceled, isRepeated } = getTransactionType({
    transactionType: item?.tipoDeParcela ?? TRANSACTION_NAME_CODE.unico
  })
  const [paymentMethod, setPaymentMethod] = useState<null | undefined | MeioPagamento>(item?.meioPagamento)

  const initialData = useMemo(() => {
    let temp = item
    if (item && !item?.repeatedParcels) {
      temp = {
        ...item,
        repeatedParcels: item?.parcelas
      }
    }

    return temp
  }, [item])

  const setItem = useCallback(
    (partialTransaction, callback?: () => void) => {
      setStateItem({
        ...item,
        ...partialTransaction
      })
      if (callback) {
        setTimeout(() => {
          callback?.()
        }, 250)
      }
    },
    [item]
  )

  // const getItem = useCallback(() => {
  //   let newItem = item
  //   if (paymentMethod && !item?.meioPagamento) {
  //     // @ts-expect-error
  //     newItem = {
  //       ...item,
  //       meioPagamento: paymentMethod
  //     }
  //   }
  //   return newItem
  // }, [item, paymentMethod])

  const findMeioPagamento = () => {
    if (item?.categoria?.meioPagamentoPadrao?.nome) {
      const meioPagamentoPadrao = MEIOS_PAGAMENTOS_OPTIONS?.find(
        (op) => op.label === item?.categoria.meioPagamentoPadrao.nome
      )
      // @ts-expect-error
      setPaymentMethod(meioPagamentoPadrao?.value)
      return
    }
    const meioId = item?.meioPagamento?.id || item?.meioPagamentoId

    if (meioId) {
      const meioPagamento = MEIOS_PAGAMENTOS_OPTIONS?.find((op) => op.value.id === meioId)
      // @ts-expect-error
      setPaymentMethod(meioPagamento?.value)
    }
  }

  const validateForm = useCallback(() => {
    const { categoria, meioPagamento, valor, valorParcela, parcelas } = item || {}

    if (!item) {
      console.error('item not found')
      return
    }

    if (categoria === null || (meioPagamento === null && !paymentMethod)) {
      setValidForm(false)
      return
    }

    if ((isRepeated || isParceled) && (!parcelas || (parcelas && parcelas <= 1))) {
      setValidForm(false)
      return
    }

    if (isEditing && (isRepeated || isParceled) && !valorParcela) {
      setValidForm(false)
      return
    }

    if (isEditing && !(isRepeated || isParceled) && !valor) {
      setValidForm(false)
      return
    }

    if (!isEditing && !valor) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }, [isEditing, isParceled, isRepeated, item, paymentMethod])

  useEffect(() => {
    if (item?.meioPagamento) {
      findMeioPagamento()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findMeioPagamento, item?.meioPagamento])

  useEffect(() => {
    console.log('paymentMethod', paymentMethod)
    setItem({
      meioPagamento: paymentMethod
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  useEffect(() => {
    validateForm()
  }, [item, validateForm])

  useEffect(() => {
    const _transaction = transaction || {}
    setItem({
      ..._transaction,
      data: transaction?.data ?? moment(new Date(), 'DD-MM-YYYY').toDate(),
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Despesa
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validForm) {
      setTransaction(item)
    }
  }, [item, setTransaction, validForm])

  // useEffect(() => {
  //   // @ts-expect-error //change type
  //   setTransaction({
  //     ...transaction,

  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <Row id="formDespesa">
      <FieldSelectOrcamento
        type={TipoOrcamento.Despesa}
        selectedId={item?.orcamentoId}
        // @ts-expect-error
        defaultValue={item?.id && item?.orcamentoId ? item?.orcamentoId : undefined}
        // @ts-expect-error
        onSelect={({ value }) => {
          // parentThis.handleChangeOrcamento({ value })
          setItem({
            orcamento: value,
            orcamentoId: value?.id
          })
        }}
      />

      <FieldSelectCategoria
        lancamentoId={item?.orcamento?.id ?? item?.orcamentoId}
        defaultValue={
          item?.categoria?.id ?? typeof item?.categoriaId === 'number' ? Number(item?.categoriaId) : undefined
        }
        onSelect={({ categoria }) => {
          const meioPagamento = categoria.meioPagamentoPadrao ?? item?.meioPagamento ?? null

          let newItem = {
            categoria: categoria,
            categoriaId: categoria?.id
          }
          console.log({ categoria })
          if (categoria?.meioPagamentoPadrao) {
            console.log('meioPagamento', meioPagamento)
            newItem = {
              ...newItem,
              // @ts-expect-error
              meioPagamento: meioPagamento,
              meioPagamentoId: meioPagamento?.id
            }
          }

          setItem(newItem, findMeioPagamento)
        }}
      />

      <Col
        lg="6"
        xl="6"
        style={
          item?.patrimonio != null &&
          (item?.patrimonio.tipoInvestimento === 3 || item?.patrimonio.tipoInvestimento === 6) &&
          item?.operacao === 1
            ? {}
            : { display: 'none' }
        }
      >
        <div>
          <label>Ativo *</label>
          {parentThis.montarDropDown(
            state.patrimonioAtivosCombo,
            'lancamentoPatrimonioAtivo',
            ({ value }) => {
              setItem({
                lancamentoPatrimonioAtivo: value
              })
            },
            item?.lancamentoPatrimonioAtivo
          )}
        </div>
      </Col>

      <FieldSelectPaymentMethods
        defaultValue={
          item?.meioPagamentoId ??
          item?.financialInstitutionCode ??
          item?.meioPagamento?.id ??
          paymentMethod?.id
        }
        onSelect={({ value }) => {
          setItem({
            meioPagamento: value,
            meioPagamentoId: value?.id
          })
        }}
      />

      <FieldInputAmount
        partialData={{
          id: item?.id,
          valor: item?.valor,
          parcelas: item?.parcelas,
          valorParcela: item?.valorParcela,
          tipoDeParcela: item?.tipoDeParcela
        }}
        onChange={(data) => {
          setItem(data)
          return
        }}
      />

      <FieldSelectDate
        id="lancamentoData"
        disabled={isEditing && (isParceled || isRepeated)}
        onChange={(date: Date) => {
          setItem({
            data: date
          })
        }}
        selectedValue={item?.data}
      />

      <Col lg="6" xl="6">
        <div>
          <InputQuantity
            onRadioChange={(value) => {
              setItem({
                tipoDeParcela: value
              })
            }}
            onInputChange={(event) => setItem({ parcelas: event.target.value })}
            parcelas={Number(item?.parcelas)}
            tipoDeParcela={item?.tipoDeParcela}
            isEditing={isEditing}
            // @ts-expect-error
            initialData={initialData}
          />
        </div>
      </Col>

      <Col lg="6" xl="6">
        <div>
          <label>Descrição</label>
          <textarea
            id="lancamentoOQue"
            onChange={(e) => {
              setItem({
                descricao: e.target.value
              })
            }}
            value={item?.descricao || ''}
            style={{ padding: 20 }}
          />
        </div>
      </Col>

      <Col lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton
          disabled={!validForm}
          transaction={{
            ...item,
            // @ts-expect-error
            meioPagamento: item?.meioPagamento ?? paymentMethod
          }}
        />
      </Col>
    </Row>
  )
}
