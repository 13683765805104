import { FixedAsset } from '../fixedAsset'
import { investmentsClient as client } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'

export const fetchFixedAssetsByFamilyId = async (familyId: number) => {
  const params = { familyId }
  const { data: assets, status } = await client.get<FixedAsset[]>('/fixedAsset', { params })
  if (status !== 200) {
    const error = new Error(`Error getting family ${familyId} fixed assets: ${status}`)
    noticeError(error)
    throw error
  }
  return assets
}
