import React, { useState } from 'react'
import { useColumns } from './columns'
import { ErrorContainer } from './TransactionsByInvestmentIdTable.styles'
import Button from '../../Buttons'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { noticeError } from '../../../services/Monitoring'
import Table from '../Table'
import { ContainerTable } from '../Table.styles'
import { useGetAllTransactions } from '../../../queries/investments/queries'
import { getLoadingManualInvestmentTransaction } from '../../../domain/investments/investments'
import { IEquityDelete } from '../EquityInvestmentsByUserIdTable/types'
import { format, parseJSON } from 'date-fns'
import { OPERATION_OPTIONS } from '../../../domain/investments/api/ManualInvestments/constants'
import { TIPO_ORCAMENTO_NAME_CODE } from '../../Modal/DeleteTransaction/constants'
import { IAllInvestment } from '../../../domain/investments/api/ManualInvestments/types'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'
import { useTransactionContext } from '../../../hooks/contexts/transactionContext'

interface Props {
  end: Date
  familyId: number
  investmentId: string
  start: Date
  equityItem: IAllInvestment
  toggledOffByDefaultColumns?: string[]
  widthBeforeHorizontalScroll?: number
  isManualInvestment?: boolean
  handleRefetch?: () => void
  setRowDeleted?: React.Dispatch<React.SetStateAction<IEquityDelete | undefined>>
  setDeleteModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const loadingData = getLoadingManualInvestmentTransaction()

export const TransactionsByManualInvestmentTable = ({
  end,
  familyId,
  investmentId,
  isManualInvestment,
  start,
  toggledOffByDefaultColumns = [],
  widthBeforeHorizontalScroll,
  equityItem,
  setRowDeleted,
  setDeleteModalOpen,
  handleRefetch
}: Props) => {
  const { setEditingEntry } = useTransactionContext()
  const [page, setPage] = useState(0)
  const token = useRequiredAuth()
  const columns = useColumns({
    handleDelete: ({ id, name, isManual }) => {
      // TODO
      // investmentId
      // transactionId
      setRowDeleted?.({ id: investmentId, transactionId: id, name, isManual, isTransaction: true })
      setDeleteModalOpen?.(true)
      handleRefetch?.()
    }
  })
  const { financialInstitutions } = useInitialInitialDataContext()

  const editTransaction = (transaction) => {
    const { financialInstitution, transactionId, description, type, quantity, enableBudgetManager } =
      transaction || {}
    const financialInstitutionCode = financialInstitutions?.find(
      ({ name }) => name === financialInstitution
    )?.key
    // amount: 78.45,
    // buyDate: '2024-06-12T20:00:04.214Z',
    // description: 'teste',
    // financialInstitution: 'Nubank',
    // investmentId: 'd785814d-58bf-4998-b8a4-99b86875797c',
    // transactionId: '4e65899c-680b-4cb8-88b2-0c84fbd85a9f',
    // quantity: null,
    // type: 'Aplicação',
    // valueQuota: null

    const copy = {
      ...transaction,
      data: format(parseJSON(transaction.buyDate), 'dd/MM/yyyy'),
      descricao: description,
      description,
      financialInstitutionCode,
      enableBudgetManager,
      id: transactionId,
      isManual: isManualInvestment,
      operacao: OPERATION_OPTIONS.find(({ label }) => label === type)?.value,
      patrimonio: {
        ...equityItem,
        category: 'Investimentos'
      },
      tipoDeParcela: transaction.parcelas <= 1 ? 99 : transaction.tipoDeParcela,
      patrimonioId: equityItem.id,
      quantidade: quantity,
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Investimento,
      pluggyInvestmentId: investmentId,
      pluggyItemId: equityItem.id,
      pluggyTransactionId: transactionId,
      valor: transaction.amount
    }

    // console.log({ transaction, copy })

    // localStorage.setItem('lancamentoEdicao', JSON.stringify(copy))
    // TODO: Improve this. Use setTransactionEdit
    // document.getElementById('novo-lancamento')?.click()
    setEditingEntry(copy)
  }

  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage(
    'investment-manual-transactions-by-investiment-id-page-size',
    10
  )

  const {
    allTransactions,
    isErrorGetAllTransactions,
    isLoadingGetAllTransactions,
    getAllTransactionsError,
    refetchGetAllTransactions
  } = useGetAllTransactions({
    investmentId: investmentId,
    params: {
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      // page,
      familyId,
      pageSize,
      pageIndex: page,
      isManualInvestment
    }
  })
  const { totalResults: totalPages } = allTransactions || {}
  const data = allTransactions?.results || []

  if (isErrorGetAllTransactions) {
    noticeError(getAllTransactionsError as Error, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetAllTransactions()} />
      </ErrorContainer>
    )
  }

  if (isLoadingGetAllTransactions) {
    return (
      <ContainerTable>
        <Table
          title=" "
          data={[]}
          columns={[]}
          isLoading={isLoadingGetAllTransactions}
          loadingData={loadingData}
          filterEnabled={false}
          pageable={true}
          pageIndex={page}
          pageSize={pageSize}
          totalPages={totalPages || 0}
          onNextPage={() => setPage(page + 1)}
          onPreviousPage={() => setPage(page - 1)}
          onPageSizeChange={setPageSize}
          toggledOffByDefaultColumns={toggledOffByDefaultColumns}
          downloadFileName={`Lançamento-Investimentos-${new Date().toISOString().split('T')[0]}`}
        />
      </ContainerTable>
    )
  }

  const handleRowClick = (row) => {
    editTransaction(row)
  }

  return (
    <ContainerTable>
      <Table
        columns={columns}
        onRowClick={handleRowClick}
        data={data}
        downloadFileName={`Lançamento-Investimentos-${new Date().toISOString().split('T')[0]}`}
        filterEnabled={false}
        isLoading={isLoadingGetAllTransactions}
        loadingData={loadingData}
        onNextPage={() => setPage(page + 1)}
        onPageSizeChange={setPageSize}
        onPreviousPage={() => setPage(page - 1)}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        title=" "
        toggledOffByDefaultColumns={toggledOffByDefaultColumns}
        totalPages={totalPages || 0}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
      />
    </ContainerTable>
  )
}
