import React from 'react'

export default () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 501 501"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M491.233 56.9A33.333 33.333 0 0 0 444.1 9.767L251 202.867 57.9
        9.767A33.333 33.333 0 0 0 10.767 56.9l193.1 193.1-193.1 193.1A33.333
        33.333 0 1 0 57.9 490.233l193.1-193.1 193.1 193.1a33.334 33.334 0 0 0
        47.133-47.133L298.133 250l193.1-193.1Z"
      fill="#323232"
    />
  </svg>
)
