import React from 'react'
import Select from './Select'
import { Selectable } from './Select/Options'

interface Props {
  selected?: number
  onChange?: (year: number) => void
}

export default ({ selected, onChange }: Props) => {
  const options: Selectable<number>[] = []
  const currentYear = new Date().getFullYear()
  const yearsRange = 5
  for (let year = currentYear; year >= currentYear - yearsRange; year--)
    options.push({ text: year.toString(), value: year })
  const placeholder =
    options.find(({ value }) => value === selected)?.text || currentYear.toString()
  if (!selected && onChange) onChange(currentYear)

  return (
    <Select
      options={options}
      placeholder={placeholder}
      onSelection={onChange}
      className="year-select"
      grayscale={true}
    />
  )
}
