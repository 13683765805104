import React from 'react'
import styled from 'styled-components'

const StyledBetaDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  margin-left: 8px;
  background: #e0d120ee;
  border-radius: 4px;
`

const StyledBetaSpan = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: 700;
  color: #471e28;
`
export default () => {
  return (
    <StyledBetaDiv>
      <StyledBetaSpan>beta</StyledBetaSpan>
    </StyledBetaDiv>
  )
}
