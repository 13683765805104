import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { isJwtValid, parseJwt } from '../../domain/auth'
import Token from '../../domain/auth/token'
import { getLoginRoute } from '../../routes'
import { useLocalStorage } from '../useLocalStorage'
import { resetEssentialsLocalStorage } from '../../components/AuthRoute/utils'

interface CommonContextValue {
  token?: Token
  setJwt: (jwt: string) => void
  /** Will be undefined when first loading de page */
  authenticated: boolean | undefined
  /** common user, planned by a planner user */
  isPlanned: boolean
  isPlanner: boolean
  isManager: boolean
  isSupport: boolean
  reset?: () => void
}

interface UnauthenticatedContextValue extends CommonContextValue {
  token: undefined
  authenticated: false | undefined
  isPlanned: false
  isPlanner: false
  isSupport: false
}

interface PlannedContextValue extends CommonContextValue {
  isPlanned: true
  isPlanner: false
  isSupport: false
  familyId: number
  pessoaId: number
}

interface PlannerContextValue extends CommonContextValue {
  isPlanned: false
  isPlanner: true
  isSupport: false
  plannerId: number
  companyId: number
}

interface SupportContextValue extends CommonContextValue {
  isPlanned: false
  isPlanner: false
  isSupport: true
}

type ContextValue =
  | PlannedContextValue
  | PlannerContextValue
  | SupportContextValue
  | UnauthenticatedContextValue

interface Props {}

const Context = createContext<ContextValue | undefined>(undefined)

const Provider: FunctionComponent<Props> = ({ children }) => {
  const { value: rawJwt, removeValue } = useLocalStorage('jwt')
  const [token, setToken] = useState<Token | undefined>(parseJwt(rawJwt ?? ''))

  const setJwt = useCallback(
    (jwt: string, callback?: () => void) => {
      if (!jwt || !isJwtValid(jwt)) {
        console.warn('Redirecting user to login page')
        removeValue()
        setToken(undefined)
        const loginRoute = getLoginRoute()
        window.location.replace((loginRoute?.layout ?? '') + loginRoute?.path)
      }
      const newToken = parseJwt(jwt)
      setToken(newToken)
      callback?.()
    },
    [removeValue, setToken]
  )

  useEffect(() => {
    if (rawJwt && !token) setJwt(rawJwt)
  }, [rawJwt, setJwt, token])

  let contextValue: ContextValue

  if (!token) {
    const complement: UnauthenticatedContextValue = {
      token,
      isPlanned: false,
      isPlanner: false,
      isSupport: false,
      isManager: false,
      authenticated: rawJwt ? undefined : false,
      setJwt
    }
    contextValue = complement
  } else if (token.pessoaId && token.familiaId) {
    const complement: PlannedContextValue = {
      token,
      isPlanned: true,
      isPlanner: false,
      isSupport: false,
      isManager: false,
      authenticated: true,
      familyId: token.familiaId,
      pessoaId: token.pessoaId,
      setJwt
    }
    contextValue = complement
  } else if (token.planejadorId && token.empresaId) {
    const complement: PlannerContextValue = {
      token,
      isPlanned: false,
      isPlanner: true,
      isManager: token.manager ?? false,
      isSupport: false,
      authenticated: true,
      plannerId: token.planejadorId,
      companyId: token.empresaId,
      setJwt
    }
    contextValue = complement
  } else if (!token.pessoaId && !token.planejadorId && !token.familiaId) {
    const complement: SupportContextValue = {
      token,
      isPlanned: false,
      isPlanner: false,
      isManager: false,
      isSupport: true,
      authenticated: true,
      setJwt
    }
    contextValue = complement
  } else throw new Error('Invalid token, unexpected combination of fields')

  return (
    <Context.Provider
      value={{
        ...contextValue,
        reset: () => {
          resetEssentialsLocalStorage()
          setToken(undefined)
          setJwt('')
        }
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAuth = () => React.useContext<ContextValue | undefined>(Context)

export { Provider as AuthProvider, Context as AuthContext }

export type { Props as AuthProviderProps, ContextValue as AuthContextValue }
