import { useMutation, useQueryClient } from 'react-query'
import { deleteManyById, Props as DeleteProps } from '../../domain/lancamento/api/deleteMany'
import {
  changeCategory as changeCategoryAPI,
  Props as ChangeProps
} from '../../domain/lancamento/api/changeCategory'
import { cacheBaseKey } from './queries'
import { editTransactionManualInvestment } from '../../domain/investments/api/ManualInvestments/manualInvestments'
import { asyncDelay } from '../../domain/timing'
import { saveTransaction } from '../../domain/lancamento/api/saveEntry'

export const useDeleteLancamentos = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const client = useQueryClient()
  const {
    mutate: deleteLancamentos,
    mutateAsync: deleteLancamentosAsync,
    isLoading: isDeletingLancamentos,
    isError: isLancamentosDeletionError,
    error: lancamentosDeletionError,
    isSuccess: deletedLancamentos,
    reset: resetLancamentosDeletion
  } = useMutation<void, Error, DeleteProps, unknown>(deleteManyById, {
    retry: false,
    onSuccess: () => {
      client.invalidateQueries(cacheBaseKey)
      onSuccess?.()
    }
  })
  return {
    deleteLancamentos,
    deleteLancamentosAsync,
    isDeletingLancamentos,
    isLancamentosDeletionError,
    lancamentosDeletionError,
    deletedLancamentos,
    resetLancamentosDeletion
  }
}

export const useChangeLancamentoCategory = () => {
  const client = useQueryClient()
  const {
    mutate: changeCategory,
    isLoading: isChangingCategory,
    isError: isCategoryChangeError,
    error: categoryChangeError,
    isSuccess: changedCategory,
    reset: resetChangeCategory
  } = useMutation<void, Error, ChangeProps, unknown>(changeCategoryAPI, {
    retry: false,
    onSuccess: () => {
      client.invalidateQueries(cacheBaseKey)
    }
  })
  return {
    changeCategory,
    isChangingCategory,
    isCategoryChangeError,
    categoryChangeError,
    changedCategory,
    resetChangeCategory
  }
}

export const useEditTransactionEquityById = () => {
  const client = useQueryClient()

  const {
    mutate: editTransactionInvestmentById,
    mutateAsync: editTransactionInvestmentByIdAsync,
    isLoading: isLoadingEditTransactionInvestmentById,
    isError: isErrorEditTransactionInvestmentById,
    error: editTransactionInvestmentByIdError,
    isSuccess: isSuccessEditTransactionInvestmentById,
    reset: resetEditTransactionInvestmentById
  } = useMutation(editTransactionManualInvestment, {
    onSuccess: () => {
      asyncDelay(200).then(() => {
        client.invalidateQueries(cacheBaseKey)
      })
    }
  })

  return {
    editTransactionInvestmentById,
    editTransactionInvestmentByIdAsync,
    isLoadingEditTransactionInvestmentById,
    isErrorEditTransactionInvestmentById,
    editTransactionInvestmentByIdError,
    isSuccessEditTransactionInvestmentById,
    resetEditTransactionInvestmentById
  }
}

export const useCreateOrUpdateTransaction = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const client = useQueryClient()

  const {
    mutate: createOrUpdateTransactionInvestment,
    mutateAsync: createOrUpdateTransactionInvestmentAsync,
    isLoading: isLoadingCreateOrUpdateTransactionInvestmentById,
    isError: isErrorCreateOrUpdateTransactionInvestmentById,
    error: createOrUpdateTransactionInvestmentError,
    isSuccess: isSuccessCreateOrUpdateTransactionInvestmentById,
    reset: resetCreateOrUpdateTransactionInvestmentById,
    data
  } = useMutation(saveTransaction, {
    onSuccess: () => {
      onSuccess?.()
      asyncDelay(1000).then(() => {
        client.invalidateQueries(cacheBaseKey)
        resetCreateOrUpdateTransactionInvestmentById()
      })
    }
  })

  const { data: createOrUpdateTransactionInvestmentData } = data || {}

  // @ts-expect-error
  const { exception, success } = createOrUpdateTransactionInvestmentData || {}

  return {
    createOrUpdateTransactionInvestment,
    createOrUpdateTransactionInvestmentAsync,
    isLoadingCreateOrUpdateTransactionInvestmentById,
    isErrorCreateOrUpdateTransactionInvestmentById,
    createOrUpdateTransactionInvestmentError,
    isSuccessCreateOrUpdateTransactionInvestmentById,
    exception,
    success
  }
}
