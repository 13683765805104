import React, { useMemo } from 'react'
import Select from '../../../Selects'
import { Selectable } from '../../../Selects/Select/Options'
import { ColumnFilterSelect } from '../columnNamesAndFilters'

export default ({ currentSelected, onChange }: ColumnFilterSelect) => {
  const options: Selectable<boolean>[] = useMemo(
    () => [
      { value: true, text: 'Sim' },
      { value: false, text: 'Não' },
    ],
    []
  )
  const placeholder = options.find(({ value }) => value === currentSelected)?.text || 'Tanto faz'

  return <Select placeholder={placeholder} options={options} onSelection={onChange} />
}
