import React from 'react'
import styled from 'styled-components'
import { Note } from '../../../domain/Notes/Notes'

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`

const Side = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #404040;
  }
`

const DateContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
  background: #88888816;
  padding: 4px;
  align-items: center;
  color: #888888;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
`

interface Props {
  item: Note
}

export default ({
  item: { _id: id, updateAt: updateAtString, createAt: createAtString },
}: Props) => {
  const updatedAt = new Date(updateAtString)
  if (isNaN(updatedAt.getTime()))
    console.warn(`Invalid updatedAt from note ${id}: ${updateAtString}`)
  const createdAt = new Date(createAtString)
  if (isNaN(createdAt.getTime()))
    console.warn(`Invalid createdAt from note ${id}: ${createAtString}`)

  return (
    <Container>
      <Side>
        <span>Criado em: </span>
        <DateContainer>{createdAt.toLocaleDateString()}</DateContainer>
      </Side>
      <Side>
        <span>Editado em: </span>
        <DateContainer>
          {updatedAt.toLocaleDateString()} {updatedAt.toLocaleTimeString()}
        </DateContainer>
      </Side>
    </Container>
  )
}
