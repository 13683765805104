import { Company } from '../company'
import { getWithToken } from '../../../webClient'

export type Params = {
  desc: boolean
  includeDeleted?: boolean
  orderBy?: string
  pageIndex: number
  pageSize: number
  search?: string
  filterDate: Date
}

export type Response = {
  companies: Company[]
  totalCompanies: number
  totalFamilies: number
  totalInvoice: number
  totalPlanners: number
}

export type ResponseFamilyBillingSummary = {
  quantityOfFamilies: number
  totalInvoice: number
}

export const getAllCompanies = async ({
  desc = true,
  includeDeleted = true,
  orderBy,
  pageIndex = 0,
  pageSize = 10,
  search,
  filterDate
}: Params) => {
  const params = { desc, includeDeleted, orderBy, pageIndex, pageSize, nameordocument: search, filterDate }
  const url = '/dashboard/CompaniesBilling'

  const { data: companies, status } = await getWithToken<Response>(url, {
    params
  })

  if (status !== 200) {
    const msg = 'Error getting all companies'
    console.error(msg)
    throw new Error(msg)
  }

  return companies
}

export const getFamilyBillingSummary = async ({ filterDate }: Pick<Params, 'filterDate'>) => {
  const params = { filterDate }
  const url = '/dashboard/family-billing-summary'

  const { data: familyBillingSummary, status } = await getWithToken<ResponseFamilyBillingSummary>(url, {
    params
  })

  if (status !== 200) {
    const msg = 'Error getting family billing summary'
    console.error(msg)
    throw new Error(msg)
  }

  return familyBillingSummary || { quantityOfFamilies: 0, totalInvoice: 0 }
}
