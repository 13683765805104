import { InvestmentsByUserId } from '../investments'
import { getWithTokenApiV2 } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'

export type Params = {
  userId: number
  desc: boolean
  orderBy?: string
  pageIndex: number
  pageSize: number
  columnLevelOne?: string
  columnLevelTwo?: string
}

export type Response = {
  groupedInvestments: InvestmentsByUserId[]
  totalAmount: number
  totalAmountOriginal: number
}

export const getInvestmentsByUserId = async ({
  userId,
  desc = true,
  pageIndex = 0,
  pageSize = 10,
  columnLevelOne,
  columnLevelTwo
}: Params) => {
  const url = `investments/investment/${userId}`
  const baseParams = {
    sort: desc ? 'desc' : 'asc',
    pageIndex,
    pageSize
  }

  let params

  if (columnLevelOne) {
    params = {
      ...baseParams,
      columnLevelOne
    }
  }
  if (columnLevelTwo) {
    params = {
      ...baseParams,
      columnLevelTwo
    }
  }

  const { data: investmentsType, status } = await getWithTokenApiV2<Response>(url, { params })

  if (status !== 200) {
    const msg = `Error getting equity investment by user id ${userId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error, { userId })
    throw new Error(msg)
  }

  return investmentsType
}
