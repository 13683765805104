import React, { useState } from 'react'
import { useCurrentFamilyId } from '../../../../hooks'
import { useFamilyOrcamentosForTable } from '../../../../queries/orcamento/queries'
import Select from '../../../Selects'
import { ColumnFilterSelect } from '../columnNamesAndFilters'

export default ({ currentSelected, onChange }: ColumnFilterSelect) => {
  const [filter, setFilter] = useState('')
  const { familyId } = useCurrentFamilyId()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)
  const options =
    orcamentos
      ?.flatMap(({ categorias }) => categorias.map(({ id, nome }) => ({ value: id, text: nome })))
      ?.filter(({ text }) => text.toLowerCase().includes(filter.toLowerCase())) ?? []
  const placeholder =
    orcamentos?.flatMap(({ categorias }) => categorias).find(({ id }) => id === currentSelected)
      ?.nome || 'Nenhum'

  return (
    <Select
      filter={filter}
      onFilter={setFilter}
      loading={isLoadingOrcamentos}
      placeholder={placeholder}
      options={options}
      onSelection={(categoriaId) => {
        setFilter('')
        onChange(categoriaId)
      }}
    />
  )
}
