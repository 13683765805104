import { noticeError } from '../../../services/Monitoring'
import { bankIntegrationClient as client } from '../../../webClient'

interface Response {
  accessToken: string
}

export const fetchPluggyToken = async (userId: number, itemId?: string) => {
  const url = `user/${userId}/token`
  const {
    status,
    data: { accessToken },
  } = await client.post<Response>(url, undefined, { params: { itemId } })
  if (status !== 201 || !accessToken) {
    const msg = `Error ${status} getting pluggy token for user ${userId}`
    const error = new Error(msg)
    noticeError(error)
    console.error(msg)
    throw error
  }
  return accessToken
}
