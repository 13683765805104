import React, { useMemo, useState } from 'react'
import { useCurrentFamilyId } from '../../../hooks'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import { FieldSelect } from '../FieldSelect'
import { Categoria } from '../../Navbars/AdminNavbar/components/SaveDataTransaction/types'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'

interface Props {
  defaultPlaceholder?: string
  label?: string
  lancamentoId?: number
  defaultValue?: number
  orcamentoType?: number
  onSelect?: ({ lancamentoId, categoria }: { lancamentoId: number; categoria: Categoria }) => void
}

export const FieldSelectCategoria = ({
  lancamentoId,
  label = 'Categoria *',
  defaultPlaceholder,
  defaultValue,
  orcamentoType,
  onSelect
}: Props) => {
  const [filter, setFilter] = useState('')
  const { familyId } = useCurrentFamilyId()
  const { categoriasFiltro, isLoadingCategoriasFiltro } = useInitialInitialDataContext()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)
  const hasOrcamentoType = typeof orcamentoType === 'number'

  let categoriasIds
  if (hasOrcamentoType) {
    categoriasIds = orcamentos?.find((o) => o?.tipo === orcamentoType)?.categorias?.map((c) => c.id)
  } else if (lancamentoId) {
    categoriasIds = orcamentos?.find((o) => o?.id === lancamentoId)?.categorias?.map((c) => c.id)
  }
  const categoriasIdsFiltradas = categoriasFiltro
    ?.filter(({ id }) => categoriasIds?.includes(id))
    ?.map((c) => ({ value: c, label: c.nome }))

  const selected = useMemo(() => {
    if (!!defaultValue && categoriasIdsFiltradas) {
      const value = categoriasIdsFiltradas?.find(({ value }) => value?.id === defaultValue)
      return value
    }
  }, [defaultValue, categoriasIdsFiltradas])

  if (isLoadingOrcamentos || isLoadingCategoriasFiltro) {
    return <></>
  }

  return (
    <FieldSelect
      label={label}
      filter={filter}
      onFilter={setFilter}
      loading={isLoadingOrcamentos || isLoadingCategoriasFiltro}
      disabled={!hasOrcamentoType && !lancamentoId}
      // @ts-expect-error
      selectedValue={selected?.value ? (selected?.value as Categoria) : undefined}
      placeholder={defaultPlaceholder || 'Selecione...'}
      // @ts-expect-error
      options={categoriasIdsFiltradas?.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      onChange={(props) => {
        const { value: categoria } = props
        // console.log({ categoria, props })
        setFilter('')
        // @ts-expect-error
        onSelect?.({ lancamentoId, categoria })
      }}
    />
  )
}
