import React, { useCallback, useState } from 'react'
import { TextInput, NumberInput } from './editableInput.styles'
import { useOnOutsideClick } from '../../Tables/hooks'
import { formatMonetaryValue } from '../../../domain/formatter'

interface TextProps {
  loading: boolean
  initialInputValue: string
  placeholder: string
  onEdit: (newText: string) => void
}

export const EditableTextInput = ({
  loading,
  initialInputValue,
  placeholder,
  onEdit,
}: TextProps) => {
  const [beingEddited, setBeingEddited] = useState(false)
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [lastConfirmedText, setlastConfirmedText] = useState(initialInputValue)

  const onConfirm = useCallback(() => {
    setBeingEddited(false)
    if (lastConfirmedText === inputValue) return
    if (inputValue === '') {
      setInputValue(initialInputValue)
      return
    }

    setlastConfirmedText(inputValue)
    onEdit(inputValue)
  }, [inputValue, lastConfirmedText, setlastConfirmedText, onEdit, initialInputValue])

  const refForOutsideClick = useOnOutsideClick(onConfirm)

  if (loading) {
    return <>Salvando...</>
  } else if (beingEddited) {
    return (
      <div ref={refForOutsideClick}>
        <TextInput
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onConfirm()
          }}
          autoFocus
          placeholder={placeholder}
          value={inputValue}
        />
      </div>
    )
  }

  return <span onClick={() => setBeingEddited(true)}>{inputValue}</span>
}

interface NumberProps {
  loading: boolean
  initialInputValue: number
  placeholder: string
  onEdit: (newValue: number) => void
}

export const EditableMonetaryInput = ({
  loading,
  initialInputValue,
  placeholder,
  onEdit,
}: NumberProps) => {
  const [beingEddited, setBeingEddited] = useState(false)
  const [inputValue, setInputValue] = useState(initialInputValue)
  const [lastConfirmedNumber, setlastConfirmedNumber] = useState(initialInputValue)

  const onConfirm = useCallback(() => {
    setBeingEddited(false)
    if (lastConfirmedNumber === inputValue) return
    if (!inputValue) {
      setInputValue(initialInputValue)
      return
    }

    setlastConfirmedNumber(inputValue)
    onEdit(inputValue)
  }, [inputValue, lastConfirmedNumber, setlastConfirmedNumber, onEdit, initialInputValue])

  const refForOutsideClick = useOnOutsideClick(onConfirm)

  if (loading) {
    return <>Salvando...</>
  } else if (beingEddited) {
    return (
      <div ref={refForOutsideClick}>
        <NumberInput
          type="number"
          onChange={(e) => setInputValue(Number(e.target.value))}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onConfirm()
          }}
          autoFocus
          placeholder={placeholder}
          value={inputValue}
        />
      </div>
    )
  }

  return <span onClick={() => setBeingEddited(true)}>{formatMonetaryValue(inputValue, false)}</span>
}
