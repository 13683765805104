import { ItemStatus } from 'pluggy-js'

const translateLabelsToPortuguese = (value: ItemStatus | string): string => {
  switch (value) {
    case ItemStatus.CREATING:
      return 'CRIANDO'
    case ItemStatus.LOGIN_ERROR:
      return 'CREDENCIAIS ERRADAS'
    case ItemStatus.MERGING:
      return 'CONSOLIDANDO DADOS'
    case ItemStatus.OUTDATED:
      return 'DESATUALIZADO'
    case ItemStatus.UPDATED:
      return 'ATUALIZADO'
    case ItemStatus.UPDATING:
      return 'ATUALIZANDO...'
    case ItemStatus.WAITING_USER_INPUT:
      return 'AGUARDANDO USUÁRIO'
    case 'TOKEN_NEEDED':
      return 'ATUALIZAR AQUI'
    default:
      return value
  }
}

export default translateLabelsToPortuguese
