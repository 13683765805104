import React, { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { useBankAndTypes } from '../../queries/extrato/queries'

const WrapperForSelect = styled.div`
  .editar {
    width: 300px !important;
  }
`

export type OnChangeBankParam = {
  bank: string
  label: string
}

interface Props {
  onChange?: (banco: OnChangeBankParam) => void
  className?: string
}

export default ({ onChange, className }: Props) => {
  const { banksAndTypes } = useBankAndTypes()
  const [value, setValue] = useState<OnChangeBankParam>()
  const banks =
    banksAndTypes?.map(({ bank, label, types }) => ({
      value: bank,
      label: label.replace('- undefined', ' '),
      types,
    })) || []

  return (
    <WrapperForSelect>
      <Select
        className={className + ' editar'}
        placeholder={'Conta Corrente / Cartão de Crédito'}
        options={banks}
        value={value}
        onChange={(selectedValue: { value: string; label: string }) => {
          if (selectedValue) {
            const response = { bank: selectedValue.value, label: selectedValue.label }
            onChange?.(response)
            setValue(response)
          }
        }}
      />
    </WrapperForSelect>
  )
}
