import React from 'react'
import styled from 'styled-components'
import defaultLogoWhitelabel from '../assets/img/whitelabel/logo_whitelabel.png'
import imgLogoVista from '../assets/img/brand/logo-nova-branca.png'
import { useTheme } from '../hooks'

const ImgLogo = styled.img<Props>`
  width: ${({ sizeW }) => sizeW || 'fit-content'};
  max-height: 100%;
  max-width: 100%;
  margin: 16px;
`

interface Props {
  sizeW?: string
}

const Logo = ({ sizeW }: Props) => {
  const {
    theme: { isWhiteLabel, isLocalFile, externalUrlLogo },
  } = useTheme()
  let src = imgLogoVista

  if (isWhiteLabel)
    if (isLocalFile) src = defaultLogoWhitelabel
    else src = externalUrlLogo

  return <ImgLogo sizeW={sizeW || '100%'} alt="Vista" src={src} />
}

export default Logo
