import React from 'react'
import Select from './Select'
import {
  FixedAssetType,
  fixedAssetType,
  translateFixedAssetType,
} from '../../domain/investments/fixedAsset'

interface Props {
  type?: FixedAssetType
  borderless?: boolean
  onChange: (type: FixedAssetType) => void
}

export default ({ type, onChange, borderless = false }: Props) => {
  const selected = type ? translateFixedAssetType(type) : 'Selecione...'
  const options = fixedAssetType.map((type) => ({
    value: type,
    text: translateFixedAssetType(type),
  }))
  return (
    <Select
      options={options}
      onSelection={onChange}
      placeholder={selected}
      grayscale={true}
      borderless={borderless}
    />
  )
}
