import { noticeError } from '../../services/Monitoring'

export default interface Theme {
  isWhiteLabel: boolean
  isLocalFile: boolean
  externalUrlLogo: string
  colors: {
    primary: string
    secondary: string
    primaryDarker: string
    primaryLighter: string
    secondaryDarker: string
    secondaryLighter: string
    backgroundLogin?: string
    backgroundSidebarManager?: string
    backgroundSidebarManagerDarker?: string
    backgroundSidebarManagerHighlightDarker?: string
  }
  login: {
    showButtonToRegister: boolean
    cardTitleText: string
    imageSize?: { x: string; y: string }
  }
  plannerHome: {
    title: string
  }
  sizes: {
    web: { x: string; y: string }
    app: { x: string; y: string }
  }
}

export type RawTheme = Omit<Theme, 'isWhiteLabel' | 'isLocalFile' | 'login'> & {
  isWhiteLabel: string | boolean
  isLocalFile: string | boolean
  login: {
    showButtonToRegister: string | boolean
    cardTitleText: string
    imageSize?: { x: string; y: string }
  }
}

export const parseRawTheme = (raw: RawTheme) => {
  const theme: Theme = {
    ...raw,
    isWhiteLabel: raw.isWhiteLabel.toString().toLowerCase() === 'true',
    isLocalFile: raw.isLocalFile.toString().toLowerCase() === 'true',
    login: {
      ...raw.login,
      showButtonToRegister: raw.login.showButtonToRegister.toString().toLowerCase() === 'true',
    },
  }
  return theme
}

export const isRawTheme = (value?: unknown): value is RawTheme =>
  value !== undefined &&
  value !== null &&
  typeof value === 'object' &&
  !Array.isArray(value) &&
  [
    'isWhiteLabel',
    'isLocalFile',
    'externalUrlLogo',
    'colors',
    'login',
    'plannerHome',
    'sizes',
  ].every((key) => {
    if (!value) {
      const error = new Error('Theme is undefined')
      noticeError(error)
      throw error
    }

    const valid = key in value
    if (!valid) console.error(`Theme is missing key: ${key}`)
    return valid
  })

export const defaultTheme: Theme = {
  isWhiteLabel: false,
  isLocalFile: true,
  externalUrlLogo: 'http://placekitten.com/g/312/168',
  colors: {
    primary: '#10a4c3',
    secondary: '#943DFF',
    primaryDarker: '#007C99',
    primaryLighter: '',
    secondaryDarker: '#7E32D0',
    secondaryLighter: '',
  },
  login: {
    showButtonToRegister: true,
    cardTitleText: 'Acesse sua conta no Vista',
    imageSize: { x: '', y: '' },
  },
  plannerHome: {
    title: 'Bem-vindo ao Meu Vista',
  },
  sizes: {
    web: { x: '', y: '' },
    app: { x: '', y: '' },
  },
}
