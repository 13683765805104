import React from 'react'
import styled from 'styled-components'

const ContainerAllRadios = styled.fieldset<{ flow: flow }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flow }) => flow};
  ${({ flow }) => flow === 'row' && 'flex-wrap: nowrap;'}
  justify-content: ${({ flow }) => (flow === 'column' ? 'center' : 'flex-end')};
  align-items: ${({ flow }) => (flow === 'row' ? 'center' : 'flex-end')};
  padding: 0 16px 0 0;
`

const ContainerItemRadio = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px 4px 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #64646412;
  }
`

const RadioButton = styled.input`
  appearance: none;
  width: 32px;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &::after {
    content: '';
    display: block;
    border-radius: 48px;
    width: 16px;
    height: 16px;
    margin: 4px;
    background: #64646480;
    transition: all 0.3s ease-in-out;
    outline: solid 2px #00000000;
    cursor: pointer;
  }
  &:hover {
    &::after {
      background: #646464dd;
    }
  }
  &:focus {
    &::after {
      outline: solid 2px ${({ theme }) => theme.colors.primaryDarker}A0;
    }
  }
  &:checked {
    &::after {
      background: ${({ theme }) => theme.colors.primary}A0;
    }
  }
`

const Label = styled.label`
  line-height: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
`

type flow = 'row' | 'column'
type items = { id: string; text: string }
interface RadioButton {
  flow?: flow
  items: items[]
  name: string
  onChangeState: (value: string) => void
}
export default ({ flow = 'row', items, name, onChangeState }: RadioButton) => {
  return (
    <ContainerAllRadios flow={flow}>
      {items.map((item, key) => (
        <ContainerItemRadio key={key}>
          <Label htmlFor={item.id}>
            <RadioButton
              type="radio"
              name={name}
              id={item.id}
              onChange={(e) => onChangeState(e.target.id)}
            />
            {item.text}
          </Label>
        </ContainerItemRadio>
      ))}
    </ContainerAllRadios>
  )
}
