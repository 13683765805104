import React, { useEffect, useMemo, useState } from 'react'
import { FieldSelect } from '../FieldSelect'
import { Props } from './types'
import { Patrimonio } from '../../Modal/DeleteTransaction/types'
import { useFetchPatrimonioByFamilyId } from '../../../queries/patrimonio/mutation'
import { useCurrentFamilyId } from '../../../hooks'

export const FieldSelectPatrimonioFinanceiroOld = ({
  selectedId,
  label = 'Patrimônio Financeiro *',
  id = 'investimentoPatrimonioFinanceiro',
  defaultPlaceholder,
  defaultValue,
  onSelect
}: Props) => {
  const { familyId } = useCurrentFamilyId()
  const [filter, setFilter] = useState('')
  const { patrimoniosByFamilyId, isLoadingPatrimonioByFamilyId, fetchPatrimonioByFamilyIdAsync } =
    useFetchPatrimonioByFamilyId()
  let OPTIONS: { label: string; value: Patrimonio }[] = []

  const selected = useMemo(
    () => (defaultValue ? OPTIONS?.find(({ value }) => value.id === defaultValue) : undefined),
    [defaultValue, OPTIONS]
  )

  OPTIONS = patrimoniosByFamilyId.map((patrimonio) => ({
    label: patrimonio.descricao,
    value: patrimonio
  }))

  const placeholder =
    (selectedId && OPTIONS?.find(({ value }) => value.id === selectedId)?.value.descricao) ||
    defaultPlaceholder ||
    'Selecione...'

  useEffect(() => {
    if (familyId) {
      fetchPatrimonioByFamilyIdAsync({ familyId, filter: { ApenasNome: true } })
    }
  }, [familyId, fetchPatrimonioByFamilyIdAsync])
  // console.log('patrimoniosByFamilyId', patrimoniosByFamilyId, OPTIONS)

  return (
    <FieldSelect
      filter={filter}
      onFilter={setFilter}
      label={label}
      id={id}
      loading={isLoadingPatrimonioByFamilyId}
      // @ts-expect-error
      options={OPTIONS?.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      placeholder={placeholder}
      onChange={({ value }) => {
        // @ts-expect-error
        onSelect?.({ value })
      }}
      // @ts-expect-error
      selectedValue={selected?.value}
    />
  )
}
