import { Categoria } from '../../components/Modal/DeleteTransaction/types'
import { TipoOrcamento } from '../orcamento/Orcamento'
import { PagedResult } from '../Results'

export interface SimplifiedPlannerList extends PagedResult<SimplifiedPlanner> {
  totalFee: number
  activeFamilies: number
  inactiveFamilies: number
}

export interface SimplifiedPlanner {
  id: number
  name: string
  document: string
  contact: string
  active: boolean
  activeFamilies: number
  inactiveFamilies: number
  totalFamilies: number
  totalFee: number
  dataCriacao: string
}

export type PlannerByCompany = {
  id: number
  name: string
  email: string
  document: string
  phone: string
  phone2: string
  address: string
  manager: boolean
  quantityOfFamilies: number
  totalInvoice: number
}

export type LonelyPlanners = {
  id: number
  name: string
  email: string
  document: string
  companyName: string
  companyDocument: string
  phone: string
  phone2?: string
  address: string
  manager: boolean
  quantityOfFamilies: number
  totalInvoice: number
}

export const getLoadingPlanner = (): SimplifiedPlanner => ({
  id: 0,
  name: '--- --- ---',
  document: '---.---.---.--',
  contact: '(--) --- --- ---',
  active: true,
  activeFamilies: 0,
  inactiveFamilies: 0,
  totalFamilies: 0,
  totalFee: 0,
  dataCriacao: ''
})

export const getLoadingPlannerByCompanyId = (): PlannerByCompany => ({
  id: 0,
  name: '--- --- ---',
  email: '--- --- ---',
  address: '--- --- ---',
  phone: '(--) ----- ----',
  phone2: '(--) ----- ----',
  document: '---.---.---.--',
  quantityOfFamilies: 0,
  totalInvoice: 0,
  manager: false
})

export const getLoadingLonelyPlanners = (): LonelyPlanners => ({
  id: 0,
  name: '--- --- ---',
  email: '--- --- ---',
  address: '--- --- ---',
  phone: '(--) ----- ----',
  phone2: '(--) ----- ----',
  document: '---.---.---.--',
  companyName: '--- --- ---',
  companyDocument: '--.---.---/---',
  quantityOfFamilies: 0,
  totalInvoice: 0,
  manager: false
})
export interface IOrcamentoFamily {
  ativo: boolean
  categorias: Categoria[] //TODO: Mapear tipo
  cep: string
  cidade: string
  complemento: string
  dataCriacao: string
  dataUltimaAtualizacao: string
  descricaoMotivoCancelamento: string
  dividas: unknown //TODO: Mapear tipo
  endereco: string
  empresaId: number
  empresa: string
  estado: string
  familiaId: number
  fimContrato: string
  historicosCategorias: unknown //TODO: Mapear tipo
  historicosPatrimonios: unknown //TODO: Mapear tipo
  historicosPlanos: unknown //TODO: Mapear tipo
  id: number
  inicioContrato: string
  lancamentos: unknown //TODO: Mapear tipo
  meiosPagamentos: unknown //TODO: Mapear tipo
  moeda: unknown //TODO: Mapear tipo
  motivoCancelamento: unknown //TODO: Mapear tipo
  motivoCancelamentoId: unknown //TODO: Mapear tipo
  nome: string
  orcamentos: unknown //TODO: Mapear tipo
  pais: string
  suspenso: number
  tipoOrcamento: TipoOrcamento
  patrimonios: unknown //TODO: Mapear tipo
  pessoas: unknown //TODO: Mapear tipo
  planos: unknown //TODO: Mapear tipo
  planejadorId: number
  planejador: {
    empresaId: number
    empresa: unknown //TODO: Mapear tipo
    nome: string
    sobrenome: string
    documento: string
    estadoCivil: number
    endereco: string
    pais: string
    cidade: string
    estado: number
    cep: string
    complemento: unknown //TODO: Mapear tipo
    celular: string
    telefone: unknown //TODO: Mapear tipo
    gestor: true
    usuario: unknown //TODO: Mapear tipo
    familias: [] //TODO: familia type
    categoriasPadrao: unknown //TODO: Mapear tipo
    orcamentosPadrao: unknown //TODO: Mapear tipo
    id: 221
    dataCriacao: string
    usuarioCriacaoId: unknown //TODO: Mapear tipo
    usuarioCriacao: unknown //TODO: Mapear tipo
    dataUltimaAtualizacao: string
    usuarioUltimaAtualizacaoId: unknown //TODO: Mapear tipo
    usuarioUltimaAtualizacao: unknown //TODO: Mapear tipo
    ativo: 1
  }
  recorrencia: unknown //TODO: Mapear tipo
  sonhos: unknown //TODO: Mapear tipo
  statusFamilia: unknown //TODO: Mapear tipo
  usuarioCriacao: unknown //TODO: Mapear tipo
  usuarioCriacaoId: number
  usuarioUltimaAtualizacao: unknown //TODO: Mapear tipo
  usuarioUltimaAtualizacaoId: number
  valorPago: unknown //TODO: Mapear tipo
}
