import { ComponentProps, lazy } from 'react'

type ComponentImportType = () => Promise<{
  default:
    | React.ComponentType
    | React.FC
    | React.ComponentClass
    // @ts-expect-error
    | ((props: ComponentProps<unknown>) => JSX.Element)
}>

const sessionKey = 'lazyWithRetry'

const lazyWithRetry = (componentImport: ComponentImportType) => {
  return lazy(async () => {
    const hasRefreshed = globalThis.sessionStorage.getItem(sessionKey) || 'false'

    try {
      globalThis.sessionStorage.setItem(sessionKey, 'false')
      return await componentImport()
    } catch (error) {
      if (hasRefreshed === 'false') {
        globalThis.sessionStorage.setItem(sessionKey, 'true')
        globalThis.location.reload()
      }

      if (hasRefreshed === 'true') throw new Error('chunkLoadError')
    }
    return await componentImport()
  })
}

export default lazyWithRetry
