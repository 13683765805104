import { useQuery } from 'react-query'
import getBankAndTypes from '../../domain/extrato/api/getBankAndTypes'
import BankAndType from '../../domain/extrato/BankAndType'

export interface BanksAndTypesResponse {
  isLoadingBanks: boolean
  isErrorBanks: boolean
  banksAndTypes: BankAndType[] | undefined
  banksAndTypesError: unknown
}

export const useBankAndTypes = (): BanksAndTypesResponse => {
  const {
    isLoading: isLoadingBanks,
    isError: isErrorBanks,
    data: banksAndTypes,
    error: banksAndTypesError,
  } = useQuery('/types', () => getBankAndTypes(), { refetchOnWindowFocus: false })

  return {
    isLoadingBanks,
    isErrorBanks,
    banksAndTypes,
    banksAndTypesError,
  }
}

export const useBankPdfTypes = (bank?: string) => {
  const { banksAndTypes } = useBankAndTypes()
  return banksAndTypes?.find((fullBank) => fullBank.bank === bank)?.typesPdf ?? []
}
