import { useMemo } from 'react'
import { Column } from 'react-table'
import { Company } from '../../../domain/company/company'

export const useColumns = () => {
  const columns = useMemo(
    (): Column<Company>[] => [
      {
        Header: 'Nome',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Documento',
        id: 'document',
        accessor: 'document',
      },
      {
        Header: 'Planejadores',
        id: 'planners',
        accessor: 'planners',
      },
      {
        Header: 'Clientes',
        id: 'customers',
        accessor: 'customers',
      },
      {
        Header: 'Fatura',
        id: 'totalInvoice',
        accessor: 'totalInvoice',
      },
    ],
    []
  )
  return columns
}
