const colors = {
  arrowGray: '#cccccc',
  black: '#000000',
  blue: '#3d94b6 ',
  checkboxBlue: '#3f51b5',
  cyan: '#90e4e4',
  darkBlue: '#306697',
  darkGray: '#323232',
  disabledTextBackground: '#f7f9fb',
  disabledTextGray: '#8898aa',
  gray: '#f5f5f5',
  grayBlue: '#e4eaf4',
  green: '#0e8730',
  highLightGray: '#cdcdcd',
  highLightGray2: '#4b4b4b',
  labelGray: '#3F5068',
  lightBlue: '#5bbfe5',
  lightGreen: '#66d1d1',
  purple: '#9a3dff',
  red: '#e80331',
  tableRowGray: '#f8f9fe',
  white: '#ffffff',
  iconDefault: '#525f7f',
  iconSuccess: '#2dce89',
} as const

type Colors = keyof typeof colors

export default colors
export type { Colors }
