import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import Colors from '../../domain/Colors'

const Container = styled.img<Props>`
  width: 100%;
  max-width: 20px;
  max-height: 20px;
  object-fit: fill;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 10px;')}
  opacity: 0.85;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`

const Tooltip = styled(ReactTooltip)`
  font-size: x-small !important;
  padding: 0.3vw !important;
  background-color: ${Colors.highLightGray2} !important;
`

interface Props {
  alt: string
  src: string
  className?: string
  tooltip?: string
  onClick?: MouseEventHandler<HTMLImageElement>
  noMargin?: boolean
}

export default ({ alt, src, className, tooltip, onClick, noMargin = false }: Props) => {
  return (
    <>
      {tooltip && (
        <Tooltip effect="solid" place="bottom" id={`icon_${tooltip}`} arrowColor="#4b4b4b" />
      )}
      <Container
        data-tip={tooltip}
        className={className}
        alt={alt}
        src={src}
        onClick={(event) => {
          event.stopPropagation()
          onClick && onClick(event)
        }}
        data-for={`icon_${tooltip}`}
        noMargin={noMargin}
      />
    </>
  )
}
