import axios, { AxiosRequestConfig } from 'axios'
import fetchIntercept from 'fetch-intercept'

/** Will add auth token to requests */
export const startFetchInterceptor = () => {
  fetchIntercept.register({
    request: async (url, config) => {
      const jwt = 'Bearer ' + localStorage.getItem('jwt')
      if (config.headers) config.headers.Authorization = jwt
      return [url, config]
    }
  })
}
startFetchInterceptor()

const bearerInterceptor = (config: AxiosRequestConfig) => {
  const jwt = 'Bearer ' + localStorage.getItem('jwt')
  if (config.headers) config.headers.Authorization = jwt
  return config
}
axios.interceptors.request.use(bearerInterceptor)

export const s3Client = axios.create({
  baseURL: process.env['REACT_APP_THEMES_API'],
  timeout: 10 * 1000
})

export const bankIntegrationClient = axios.create({
  baseURL: process.env['REACT_APP_BANK_INTEGRATION_API'],
  timeout: 10 * 1000
})
bankIntegrationClient.interceptors.request.use(bearerInterceptor)

export const apiWebClient = axios.create({
  baseURL: process.env['REACT_APP_API_BASE_URL'],
  timeout: 10 * 1000
})
apiWebClient.interceptors.request.use(bearerInterceptor)

export const extratosApi = axios.create({
  baseURL: process.env['REACT_APP_API_EXTRATOS']
})
extratosApi.interceptors.request.use(bearerInterceptor)

export const investmentsClient = axios.create({
  baseURL: process.env['REACT_APP_INVESTMENTS_URL'],
  timeout: 10 * 1000,
  validateStatus: () => true
})
investmentsClient.interceptors.request.use(bearerInterceptor)

export const annotationsApi = axios.create({
  baseURL: process.env['REACT_APP_API_ANNOTATIONS']
})
annotationsApi.interceptors.request.use(bearerInterceptor)

export const authClient = axios.create({
  baseURL: process.env['REACT_APP_AUTH_BASE_URL'],
  timeout: 10 * 1000,
  validateStatus: () => true
})

export const postWithToken = <Response, Body>(url: string, body?: Body, config?: AxiosRequestConfig) => {
  return apiWebClient.post<Response>(`${url}`, body, config)
}

export const putWithToken = <Response, Body>(url: string, body?: Body) => {
  return apiWebClient.put<Response>(`${url}`, body)
}

export const deleteWithToken = <Response, Body>(url: string, body?: Body, config?: AxiosRequestConfig) => {
  return apiWebClient.delete<Response>(`${url}`, { ...config, data: body })
}

export const getWithToken = <Response>(url: string, config?: AxiosRequestConfig) => {
  return apiWebClient.get<Response>(`${url}`, config)
}

const apiWebClientV2 = axios.create({
  baseURL: process.env['REACT_APP_AUTH_BASE_URL'],
  timeout: 30 * 1000
})
apiWebClientV2.interceptors.request.use(bearerInterceptor)

export const getWithTokenApiV2 = <Response>(url: string, config?: AxiosRequestConfig) => {
  return apiWebClientV2.get<Response>(`${url}`, config)
}

export const patchWithTokenApiV2 = <Response, Body>(
  url: string,
  body?: Body,
  config?: AxiosRequestConfig
) => {
  return apiWebClientV2.patch<Response>(`${url}`, body, config)
}

export const postWithTokenApiV2 = <Response, Body>(url: string, body?: Body, config?: AxiosRequestConfig) => {
  return apiWebClientV2.post<Response>(`${url}`, body, config)
}

export const deleteWithTokenApiV2 = <Response, Body>(
  url: string,
  body?: Body,
  config?: AxiosRequestConfig
) => {
  return apiWebClientV2.delete<Response>(`${url}`, { ...config, data: body })
}
