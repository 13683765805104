import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import AdminLayout from './layouts/Admin.jsx'
import AuthLayout from './layouts/Auth.jsx'
import { AuthRoute } from './components/AuthRoute'
import { LoadingBackground } from './components/Loading'

import './global'
import './assets/css/global.css'
import './assets/vendor/nucleo/css/nucleo.css'
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/argon-dashboard-react.css'
import { AuthProvider, CoreContextProvider } from './hooks/contexts'

const cacheTime = 30 * 1000
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime,
      retry: 2,
      retryDelay: 2000,
      refetchOnWindowFocus: false
    }
  }
})
const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
  key: 'REACT_QUERY_OFFLINE_CACHE'
})
persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  maxAge: cacheTime
})

const reactDevToolsIsEnabled = process.env.REACT_APP_DEV_TOOLS === 'true'
if (!reactDevToolsIsEnabled) {
  disableReactDevTools()
}

const App = () => (
  <Suspense fallback={<LoadingBackground />}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CoreContextProvider>
          <BrowserRouter basename="/v1">
            <Switch>
              <AuthRoute path="/admin" render={(props) => <AdminLayout betaUser={false} {...props} />} />
              <AuthRoute path="/auth" render={(props) => <AuthLayout {...props} />} />
              <Redirect from="/" to="/admin/home" />
            </Switch>
          </BrowserRouter>
        </CoreContextProvider>
      </AuthProvider>
    </QueryClientProvider>
  </Suspense>
)

const appContainer = document.getElementById('root')

if (appContainer == null) console.error('No app container found')
ReactDOM.render(<App />, document.getElementById('root'))
