import React, { useEffect, useReducer } from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'
import { ClearBackgroundButton } from '../../Buttons'
import { useOnOutsideClick } from '../hooks'
import { availableColumnNames, AvailableColumnNames, getColumnSelect } from './columnNamesAndFilters'

const defaultTitle = 'FILTROS'

interface Props {
  title?: string
  columnNames: AvailableColumnNames[]
  onFilterChange: (columnName: AvailableColumnNames, value?: string | number | boolean) => void
  onOutsideClick?: () => void
  filterDefaultSelected?: { [key in AvailableColumnNames]?: string | number | boolean }
}

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  padding: 0.5vw;
  width: 16vw;
  left: -15vw;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 100;
  gap: 8px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  & > p {
    font-weight: 600;
    line-height: 1;
    padding: 0;
    margin: 0;
  }
`

const SelectContainer = styled.div`
  padding: 20px 8px 8px 8px;
  border: solid 1px #ddd;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`

const LabelSelect = styled.label`
  position: absolute;
  top: -20px;
  left: 8px;
  padding: 8px;
  background: #fff;
`

interface FilterState {
  [key: string]: string | number | boolean | undefined
}

export default ({
  title = defaultTitle,
  columnNames,
  onFilterChange,
  onOutsideClick = () => null,
  filterDefaultSelected
}: Props) => {
  const [filters, dispatchFilter] = useReducer(
    (
      state: FilterState,
      action: {
        type: 'set' | 'clear'
        column?: AvailableColumnNames
        value?: string | number | boolean
      }
    ) => {
      const { type, column, value } = action
      switch (type) {
        case 'set':
          if (!column) {
            console.warn(`Filter set action called without column(${column}) or value(${value})`)
            return state
          }
          onFilterChange(column, value)
          return { ...state, [column]: value }
        case 'clear':
          for (const column of availableColumnNames) {
            onFilterChange(column, false)
          }
          return {}
        default:
          console.warn(`Unhandled filter action type: ${type}`)
          return state
      }
    },
    {} as FilterState
  )
  const getColumnFilterValue = (name: AvailableColumnNames) => filters[name]
  const clear = useCallback(() => dispatchFilter({ type: 'clear' }), [dispatchFilter])
  const setFilter = useCallback(
    (column: AvailableColumnNames, value?: string | number | boolean) =>
      dispatchFilter({ type: 'set', column, value }),
    [dispatchFilter]
  )
  const ref = useOnOutsideClick(onOutsideClick)

  useEffect(() => {
    if (filterDefaultSelected) {
      for (const column of columnNames) {
        setFilter(column, filterDefaultSelected[column])
      }
    }
  }, [columnNames, filterDefaultSelected, setFilter])

  // useEffect(() => {
  //   if (!filterMethods) return
  //   filterMethods?.setFilters(filters)
  // }, [])

  return (
    <Container ref={ref}>
      <TitleContainer>
        <p>{title}</p>
        <ClearBackgroundButton text="Limpar" onClick={clear} />
      </TitleContainer>
      {columnNames.map((column, i) => {
        const Select = getColumnSelect(column)
        const currentlySelected = getColumnFilterValue(column)

        const changeSelectedValue = useCallback(
          (value?: string | number | boolean) => {
            setFilter(column, value)
          },
          [column]
        )

        return (
          <SelectContainer key={i}>
            <LabelSelect>{column}</LabelSelect>
            <Select currentSelected={currentlySelected} onChange={changeSelectedValue} />
          </SelectContainer>
        )
      })}
    </Container>
  )
}
