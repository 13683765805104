import { useQuery } from 'react-query'
import {
  fetchPatrimonioFinancialInstitutionsApi,
  fetchPatrimoniosAtivosRendaFixaApi
} from '../../domain/patrimonio'
import { useCoreContext } from '../../hooks/contexts/coreProvider'

export const cacheKey = 'patrimonio'

export const useFetchPatrimoniosAtivosRendaFixa = ({ familyId }) => {
  const { authenticated } = useCoreContext()
  const {
    data,
    isError: isErrorFetchPatrimoniosAtivosRendaFixa,
    isLoading: isLoadingFetchPatrimoniosAtivosRendaFixa,
    error: errorFetchPatrimoniosAtivosRendaFixa,
    refetch: refetchFetchPatrimoniosAtivosRendaFixa
  } = useQuery([cacheKey, 'transactions'], () => fetchPatrimoniosAtivosRendaFixaApi({ familyId }), {
    enabled: authenticated && !!familyId
  })

  const { patrimoniosAtivosRendaFixa } = data || {}

  return {
    patrimoniosAtivosRendaFixa,
    isErrorFetchPatrimoniosAtivosRendaFixa,
    isLoadingFetchPatrimoniosAtivosRendaFixa,
    errorFetchPatrimoniosAtivosRendaFixa,
    refetchFetchPatrimoniosAtivosRendaFixa
  }
}

export const useFetchPatrimoniosFinancialInstitutions = () => {
  const { authenticated, familiaId } = useCoreContext()

  const {
    data,
    isError: isErrorFetchPatrimoniosFinancialInstitutions,
    isLoading: isLoadingFetchPatrimoniosFinancialInstitutions,
    error: errorFetchPatrimoniosFinancialInstitutions,
    refetch: refetchFetchPatrimoniosFinancialInstitutions
  } = useQuery([cacheKey, 'transactions'], () => fetchPatrimonioFinancialInstitutionsApi(), {
    enabled: authenticated && !!familiaId
  })

  const { financialInstitutions: patrimoniosFinancialIntitution } = data || {}

  return {
    patrimoniosFinancialIntitution,
    isErrorFetchPatrimoniosFinancialInstitutions,
    isLoadingFetchPatrimoniosFinancialInstitutions,
    errorFetchPatrimoniosFinancialInstitutions,
    refetchFetchPatrimoniosFinancialInstitutions
  }
}
