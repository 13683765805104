import React from 'react'
import { Col } from 'reactstrap'
import CurrencyInput from 'react-currency-input'
import { getTransactionType, TTransactionTypes } from '../../domain/lancamento/lancamento'
interface InputAmountProps {
  label?: string
  size?: { lg: number; xl: number }
  value?: string | number
  onChange: (value: {
    valorFormatado: string
    valor: number
    editRepeated?: boolean
    editInstallments?: boolean
    valorParcela?: number | null
    parcelado?: boolean
    parcelas?: number | null
  }) => void
  disabled?: boolean
  partialData: {
    id?: number
    valor?: number
    parcelas?: number
    valorParcela?: number
    tipoDeParcela?: TTransactionTypes | null
  }
}

export const FieldInputAmount = ({
  size = { lg: 6, xl: 6 },
  label = 'Valor',
  onChange,
  partialData,
  disabled
}: InputAmountProps) => {
  const { isParceled, isRepeated } = getTransactionType({ transactionType: partialData?.tipoDeParcela })
  const isEditing = !!partialData?.id
  const getLabel = () => {
    let text = label

    if (isParceled) {
      if (!isEditing) text = 'Valor total'
      if (isEditing) text = 'Valor da parcela'
    }

    return text
  }

  const parceledOrRepeted = isParceled || isRepeated

  return (
    <Col lg={size.lg} xl={size.xl}>
      <div>
        <label>{getLabel()}*</label>

        <CurrencyInput
          id="lancamentoValor"
          style={{ height: 38, paddingLeft: 25 }}
          value={isEditing && (isParceled || isRepeated) ? partialData?.valorParcela : partialData?.valor}
          thousandSeparator={'.'}
          decimalSeparator={','}
          precision="2"
          disabled={disabled}
          onChangeEvent={(_, formattedValue, value) => {
            if (isEditing) {
              onChange({
                valor: value,
                editRepeated: isRepeated,
                editInstallments: isParceled,
                valorParcela: parceledOrRepeted ? value : null,
                parcelado: parceledOrRepeted,
                parcelas: parceledOrRepeted && partialData?.parcelas ? partialData?.parcelas : null,
                valorFormatado: formattedValue
              })
              return
            }

            onChange({
              valor: value,
              valorFormatado: formattedValue
            })
          }}
        />
      </div>
    </Col>
  )
}
