import React from 'react'
import { MapProps } from './types'
import { IframeVimeo } from './components/VideoIframe/Iframe'
import { Pagination } from './components/Pagination'

export const HelpPlannerHome = () => (
  <div>
    <p>
      A plataforma Meu Vista oferece um modelo de precificação simples e transparente para os planejadores
      financeiros. A mensalidade é calculada com base no número de famílias ativas pelo planejador no primeiro
      dia de cada mês. O custo mensal por família é de R$ 49,90.{' '}
    </p>

    <p>
      Por exemplo, se um planejador financeiro tem o total cadastrado de 10 famílias no primeiro dia do mês, a
      mensalidade a ser paga ao "Meu Vista" será de R$ 499,00 (10 famílias x R$ 49,90 por família). Este
      modelo facilita o planejamento financeiro dos profissionais, permitindo que eles prevejam com
      antecedência os custos associados ao uso da plataforma.
    </p>

    <p>
      A cobrança da mensalidade é efetuada no dia 10 daquele mesmo mês. Assim, os planejadores financeiros têm
      tempo suficiente para organizar suas finanças e garantir que os pagamentos sejam realizados de forma
      pontual.
    </p>

    <p>
      Este método de precificação é vantajoso tanto para os planejadores financeiros iniciantes, que podem ter
      um menor número de clientes, quanto para os mais experientes, que atendem a um número maior de famílias.
      Com isso, o Meu Vista se torna uma ferramenta acessível e escalável para profissionais de diferentes
      estágios de suas carreiras.
    </p>

    <IframeVimeo title="Home (Ajuda)" videoId="893790305" />
  </div>
)

export const HelpPlannerUsuarios = () => (
  <Pagination>
    <div>
      <h3>Como funciona a cobrança no Vista?</h3>

      <p>
        O Vista é uma <b>plataforma tecnológica</b> para o planejador financeiro atender seus clientes.
      </p>

      <p>
        O cliente é do Planejador, e não do Vista, portanto, o contrato entre o planejador e o cliente deve
        ser feito entre as partes.
      </p>

      <p>
        Oferecemos 7 dias como período de desistência. Portanto todo novo cliente poderá ser cancelado em até
        7 dias sem gerar custos. A partir do 8º dia, será cobrado o valor desde o início.
      </p>

      <p>O planejador poderá escolher entre os dois planos:</p>

      <p>
        <b>• Plano Individual:</b> 49,90 por família
        <br />
        <b>• Plano Família:</b> 59,90 por família'
      </p>

      <IframeVimeo title="Home (Ajuda)" videoId="893791918" />
    </div>

    <div>
      <h3>Mais sobre os Planos</h3>

      <p>
        <b>Plano Individual:</b> ideal para solteiros, clientes que não possuem investimentos ou casais que
        possuem 1 carteira de investimentos (Ex: o trabalho é feito com uma família, mas somente um dos
        cônjuges possui investimentos no seu nome).
      </p>

      <p>
        <b>Plano Família:</b> ideal para famílias que possuem 2 carteiras de investimentos (Ex: o trabalho é
        feito com uma família, sendo que cada um dos cônjuges possui investimentos no seu nome). <br />
        <br />* Os planos podem ser modificados a qualquer momento.
      </p>

      <h4>Qual o dia da cobrança?</h4>

      <p>
        Todo dia 10 de cada mês subsequente ao cadastramento do cliente, o planejador pagará a sua fatura
        mensal referente aos seus clientes, de acordo com o plano escolhido. O pagamento pode ser feito
        através do cartão de crédito ou débito. Na primeira cobrança o planejador receberá um link no seu
        e-mail para cadastrar o cartão de crédito ou débito, e aquele cartão ficará salvo para as próximas
        faturas.
      </p>

      <p>
        Ex: Planejador tem 3 clientes no Plano Individual de 49,90 (total de 149,70) e 4 clientes no Plano
        Família de 59,90 (239,60). Além disso, o planejador utiliza o gateway de pagamento de 59,90.
      </p>

      <p>Total da Fatura = 149,70 + 239,60 + 59,90 = 449,20.</p>

      <p>Esse valor será cobrado no cartão de crédito/débito cadastrado do planejador.</p>

      <p>
        <b>Importante:</b> O valor cobrado pelo cliente será pro-rata. Ou seja, caso o planejador cadastre o
        cliente durante o mês (ou cancele o cliente durante o mês), ele irá pagar o valor proporcional do
        cliente na próxima fatura.
      </p>
    </div>
  </Pagination>
)

export const HelpPlannerClientes = () => (
  <Pagination>
    <div>
      <h3>Como funciona a cobrança no Vista?</h3>
      <p>
        Como funciona a cobrança no Vista?|O Vista é uma <strong>plataforma tecnológica</strong> para o
        planejador financeiro atender seus clientes.
      </p>
      <p>
        O cliente é do Planejador, e não do Vista, portanto, o contrato entre o planejador e o cliente deve
        ser feito entre as partes.
      </p>
      <p>
        Oferecemos 7 dias como período de desistência. Portanto todo novo cliente poderá ser cancelado em até
        7 dias sem gerar custos. A partir do 8º dia, será cobrado o valor desde o início.
      </p>
      <p>O planejador poderá escolher entre os dois planos:</p>

      <ul>
        <li>
          <strong>Plano Individual:</strong> 49,90 por família
        </li>
        <li>
          <strong>Plano Família:</strong> 59,90 por família
        </li>
      </ul>

      <h4>Tutorial:</h4>
      <IframeVimeo videoId="893795767" title="Clientes (ajuda)" />
    </div>

    <div>
      <p>
        <strong>Mais sobre os Planos</strong>
      </p>
      <p>
        <strong>Plano Individual:</strong> ideal para solteiros, clientes que não possuem investimentos ou
        casais que possuem 1 carteira de investimentos (Ex: o trabalho é feito com uma família, mas somente um
        dos cônjuges possui investimentos no seu nome).
      </p>
      <p>
        <strong>Plano Família:</strong> ideal para famílias que possuem 2 carteiras de investimentos (Ex: o
        trabalho é feito com uma família, sendo que cada um dos cônjuges possui investimentos no seu nome).
      </p>

      <p>* Os planos podem ser modificados a qualquer momento.</p>
    </div>

    <div>
      <h3>
        <strong>Qual o dia da cobrança?</strong>
      </h3>
      <p>
        Todo dia 10 de cada mês subsequente ao cadastramento do cliente, o planejador pagará a sua fatura
        mensal referente aos seus clientes, de acordo com o plano escolhido. O pagamento pode ser feito
        através do cartão de crédito ou débito.
      </p>

      <p>
        Na primeira cobrança o planejador receberá um link no seu e-mail para cadastrar o cartão de crédito ou
        débito, e aquele cartão ficará salvo para as próximas faturas.
      </p>

      <p>
        Ex: Planejador tem 3 clientes no Plano Individual de 49,90 (total de 149,70) e 4 clientes no Plano
        Família de 59,90 (239,60). Além disso, o planejador utiliza o gateway de pagamento de 59,90.
      </p>

      <p>Total da Fatura = 149,70 + 239,60 + 59,90 = 449,20.</p>

      <p>Esse valor será cobrado no cartão de crédito/débito cadastrado do planejador.</p>

      <p>
        <strong>Importante:</strong> O valor cobrado pelo cliente será pro-rata. Ou seja, caso o planejador
        cadastre o cliente durante o mês (ou cancele o cliente durante o mês), ele irá pagar o valor
        proporcional do cliente na próxima fatura.
      </p>
    </div>
  </Pagination>
)

const HelpPlannerFatura = () => (
  <div>
    <h3>Tutorial</h3>
    <IframeVimeo title="Fatura Vista" videoId="893790305" />
  </div>
)

export const MAP_PLANNER: MapProps = {
  '/home': HelpPlannerHome,
  '/clientes': HelpPlannerClientes,
  '/usuarios': HelpPlannerUsuarios,
  '/faturas': HelpPlannerFatura
}
