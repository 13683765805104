import React from 'react'
import { Redirect } from 'react-router-dom'
import { getLoginRoute, getRoute } from '../../routes'

export const getLoginPath = () => {
  const loginRoute = getLoginRoute()
  return (loginRoute?.layout ?? '') + loginRoute?.path
}

export const resetEssentialsLocalStorage = async () => {
  localStorage.removeItem('access-token')
  localStorage.removeItem('jwt')
  localStorage.removeItem('dataInicio')
  localStorage.removeItem('dataFim')
  localStorage.removeItem('empresa-id')
  localStorage.removeItem('familia-id')
  localStorage.removeItem('pessoa-id')
  localStorage.removeItem('planejador-id')
  localStorage.removeItem('planejador-principal')
  localStorage.removeItem('tipo-usuario')
  localStorage.removeItem('apiToken')
  localStorage.removeItem('usuario-id')
  localStorage.removeItem('usuario-email')
  localStorage.setItem('erroLogin', 'false')
  localStorage.removeItem('patrimonio-migracao-showModal')
}
export const RedirectToLogin = () => {
  const loginPath = getLoginPath()

  resetEssentialsLocalStorage()
  console.warn('redirecting unauthenticated user to login page')

  return <Redirect to={loginPath} />
}

export const RedirectToHome = () => {
  const route = getRoute('Home')
  if (!route) throw new Error('Login route not found')

  return <Redirect to={String(route?.path)} />
}
