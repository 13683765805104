import React, { useEffect } from 'react'
import { NewModal } from '../NewModal'
import { UserType, userTypes } from '../../../domain/user/userType'
import { MAP_PLANNER } from './helpPlanner'
import { MAP } from './help'
import { getRouteNameByPath } from '../../../routes'
import { HelpModalProps } from './types'

// const ModalNotFound = () => <div>Modal não encontrado</div>

export const HelpModal = ({ userType: type, open, setIsOpen }: HelpModalProps) => {
  const pageId = window.location.pathname.replace(/\/v1\/admin/, '')
  const familiaId = localStorage.getItem('familia-id')
  const userType: UserType = userTypes[type]
  const routeName = getRouteNameByPath(pageId) ?? pageId

  let Page

  const handleClose = () => {
    setIsOpen(false)
    globalThis.bodyScroll(false)
  }

  if (['planejador', 'admin'].includes(userType) && familiaId === null) {
    Page = MAP_PLANNER[pageId]

    if (!Page) {
      Page = MAP[pageId]
    }
  } else {
    Page = MAP[pageId]
  }

  useEffect(() => {
    if (open && pageId) {
      localStorage.setItem(window.location.pathname, 'ok')
      globalThis.bodyScroll(open)
    }
  }, [open, pageId])

  if (!Page) {
    return <></>
  }

  return (
    <NewModal open={open} onClose={handleClose} title={routeName.replace(/\//gi, '')}>
      {Page()}
    </NewModal>
  )
}
