import React, { useCallback, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import CurrencyInput from 'react-currency-input'
import ResgatarTudo from '@material-ui/icons/MonetizationOn'
import { Input, Row, Col } from 'reactstrap'
import { INVESTMENT_NAME_CODE, OPERACAO_NAME_CODE } from './types'
import { getTransactionType, TRANSACTION_NAME_CODE } from '../../../../../domain/lancamento/lancamento'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { FieldSelect } from '../../../../Selects/FieldSelect'
import { OPERATION_OPTIONS } from '../../../../../domain/investments/api/ManualInvestments/constants'
import { noticeError } from '../../../../../services/Monitoring'
import { Patrimonio } from '../../../../Modal/DeleteTransaction/types'
import { FieldSelectPatrimonioFinanceiroOld } from '../../../../Selects/FieldSelectPatrimonioFinanceiroOld'
import { FieldSelectFinancialInstitutions } from '../../../../Selects/FieldSelectFinancialInstitutions'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { TIPO_ORCAMENTO_NAME_CODE, Transaction } from '../SaveDataTransaction/types'
import { FieldSelectDate } from '../../../../Selects/FieldSelectDate'
import moment from 'moment'

const REDEEM_OPTIONS = [
  { value: 1, label: 'Resgate' },
  { value: 3, label: 'Come Cotas' }
]

export const FormInvestimento = ({ parentThis }) => {
  const { SaveButton } = useSaveTransactionsActions()
  const { transaction, setTransaction } = useTransactionContext()
  const [item, setStateItem] = useState<Transaction | undefined>({
    ...transaction,
    tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Investimento
  } as Transaction)
  const { state } = parentThis
  const isEditing = item?.id
  const [validForm, setValidForm] = useState(false)
  const { isParceled, isRepeated } = getTransactionType({
    transactionType: item?.tipoDeParcela ?? TRANSACTION_NAME_CODE.unico
  })
  const isSaling = item?.operacao === OPERACAO_NAME_CODE.VENDA
  const valuePatrimonio =
    typeof item?.patrimonio?.id === 'number' &&
    state?.patrimonioAtivos?.[item?.patrimonio?.id]?.[0]?.['value']
  const patrimonioSelecionado = state?.patrimonioAtivosDados[valuePatrimonio]

  const isRendaFixaPos = item?.patrimonio?.tipoInvestimento === INVESTMENT_NAME_CODE.RendaFixaPos
  const isRendaFixaPre = item?.patrimonio?.tipoInvestimento === INVESTMENT_NAME_CODE.RendaFixaPre
  const isRendaFixa =
    typeof item?.patrimonio?.tipoInvestimento === 'number' &&
    [INVESTMENT_NAME_CODE.RendaFixaPos, INVESTMENT_NAME_CODE.RendaFixaPre].includes(
      item?.patrimonio?.tipoInvestimento
    )
  const isAcoesMoedas =
    typeof item?.patrimonio?.tipoInvestimento === 'number' &&
    [8, 10, 99].includes(item?.patrimonio?.tipoInvestimento)
  const priceTitle = isAcoesMoedas ? 'Preço unitário' : !isSaling ? 'Valor total aplicado' : 'Valor resgatado'

  const investmentsGroup = [
    INVESTMENT_NAME_CODE.Fundos,
    INVESTMENT_NAME_CODE.Previdencia,
    INVESTMENT_NAME_CODE.RendaFixaPos,
    INVESTMENT_NAME_CODE.TesouroDireto,
    INVESTMENT_NAME_CODE.RendaFixaPre,
    INVESTMENT_NAME_CODE.Ações,
    INVESTMENT_NAME_CODE.Debentures
  ]

  const investmentGroup =
    typeof item?.patrimonio?.tipoInvestimento === 'number' &&
    [
      INVESTMENT_NAME_CODE.TesouroDireto,
      INVESTMENT_NAME_CODE.Ações,
      INVESTMENT_NAME_CODE.Moeda,
      INVESTMENT_NAME_CODE.Customizado
    ].includes(item?.patrimonio?.tipoInvestimento)

  const setItem = (data, callback?: () => void) => {
    setStateItem({
      ...item,
      ...data
    })
    if (callback) {
      setTimeout(() => {
        callback?.()
      }, 100)
    }
  }

  // const findMeioPagamento = useCallback(() => {
  //   const { meiosPagamentoCombo } = state
  //   if (item && meiosPagamentoCombo) {
  //     if (item?.meioPagamento && item?.meioPagamento?.nome) {
  //       const meioPagamento = meiosPagamentoCombo?.find((op) => op.label === item?.meioPagamento.nome)
  //       setPaymentMethod(meioPagamento)
  //     } else if (item?.categoria?.meioPagamentoPadrao?.nome) {
  //       const meioPagamentoPadrao = meiosPagamentoCombo.find(
  //         (op) => op.label === item?.categoria.meioPagamentoPadrao.nome
  //       )
  //       setPaymentMethod(meioPagamentoPadrao)
  //     }
  //   }
  // }, [item, state])

  const handleChangePatrimonio = (selectedOption) => {
    const { categorias, patrimonioAtivos, item, familiaId } = state
    const patrimonio = selectedOption?.value || {}
    let categoria = null
    let patrimonioAtivosCombo: { value: string }[] = []
    let patrimonioAtivoId: string | null = null

    if (patrimonio) {
      const nomeCategoria = patrimonio.descricao

      categoria = categorias.find(
        ({ nome, orcamento }) =>
          nomeCategoria.toLowerCase() === nome.toLowerCase() && orcamento?.tipoOrcamento === 2
      )

      if (!categoria) {
        const msg = `Categoria não encontrada ${nomeCategoria}`
        console.error(msg)
        const error = new Error(msg)
        // TODO: Decide if we should throw error
        noticeError(error, { familiaId, selectedOption })
      }

      patrimonioAtivosCombo = patrimonioAtivos[patrimonio.id]
      patrimonioAtivoId = patrimonio.id
    }

    const updatedItem = {
      ...item,
      quantidade: 0,
      valor: 0,
      patrimonio,
      categoria,
      // @ts-expect-error
      orcamento: categoria?.orcamento ?? item?.orcamento,
      patrimonioAtivoId
    }

    parentThis?.setState({ patrimonioAtivosCombo, item: updatedItem })
  }

  const validateForm = useCallback(() => {
    if (!item) {
      console.error('item not found')
      return
    }
    const { operacao, patrimonio, valor } = item || {}

    const basicFields = operacao === null || patrimonio === null || valor === null || valor === undefined

    if (basicFields) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }, [item])

  const PATRIMONIO_OPTIONS: { label: string; value: Patrimonio }[] = []
  // const FINANCIAL_INSTITUTION_OPTIONS: { label: string; value: number }[] = []

  // useCallback(
  //   () =>
  //     financialInstitutions?.forEach((institutionItem) => {
  //       FINANCIAL_INSTITUTION_OPTIONS.push({
  //         label: institutionItem?.value,
  //         value: institutionItem?.key
  //       })
  //     }),
  //   [financialInstitutions, FINANCIAL_INSTITUTION_OPTIONS]
  // )

  const defaultValuePatrimonio = useCallback(() => {
    if (item?.patrimonio) return item?.patrimonio

    const selectedInvestment = PATRIMONIO_OPTIONS?.find(
      (investmentItem) => investmentItem?.value?.id === item?.patrimonioId
    )

    return selectedInvestment?.value
  }, [PATRIMONIO_OPTIONS, item])

  // const getItem = () => {
  //   let newItem = item
  //   if (paymentMethod && !item?.meioPagamento) {
  //     // @ts-expect-error
  //     newItem = {
  //       ...item,
  //       meioPagamento: paymentMethod
  //     }
  //   }
  //   return newItem
  // }

  const getAmonut = () => {
    if (!item) return ''
    let amount = ''
    if (
      item?.patrimonio?.tipoInvestimento === 8 ||
      item?.patrimonio?.tipoInvestimento === 10 ||
      item?.patrimonio?.tipoInvestimento === 99
    ) {
      amount = (
        (item?.valor != null ? item?.valor : 0) *
        (item?.quantidade != null && item?.quantidade >= 0 ? item?.quantidade : 0)
      ).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    } else if (item?.patrimonio != null && item?.patrimonio?.tipoInvestimento === 4 && item?.operacao === 1) {
      amount = (item?.valor != null ? item?.valor : 0).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    } else {
      amount = (
        (item?.valor != null ? item?.valor : 0) /
        (item?.quantidade != null && item?.quantidade > 0 ? item?.quantidade : 1)
      ).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6
      })
    }

    return amount
  }

  // useEffect(() => {
  //   if (familyId) {
  //     fetchPatrimonioFinancialInstitutionsAsync()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   setItem({
  //     meioPagamento: paymentMethod
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentMethod])

  // useEffect(() => {
  //   findMeioPagamento()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [findMeioPagamento, item?.meioPagamento])

  useEffect(() => {
    setItem({
      ...transaction,
      data: transaction?.data ?? moment(new Date(), 'DD-MM-YYYY').toDate(),
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Investimento
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction])

  useEffect(() => {
    validateForm()
  }, [item, validateForm])

  return (
    <Row id="formInvestment">
      <FieldSelect
        label="Operação *"
        id="lancamentoOperacao"
        options={OPERATION_OPTIONS}
        // loading={isLoadingPatrimonioByFamilyId}
        // @ts-expect-error
        onChange={({ value }: { value: number }) => {
          setItem({ operacao: value })
          // parentThis?.setState({
          //   item: {
          //     ...item,
          //     operacao: value
          //   }
          // })
        }}
        // @ts-expect-error
        selectedValue={item?.operacao}
      />

      {/* <FieldSelect
        label="Patrimônio Financeiro *"
        id="investimentoPatrimonioFinanceiro"
        disabled={!PATRIMONIO_OPTIONS?.length}
        options={PATRIMONIO_OPTIONS}
        loading={isLoadingPatrimonioByFamilyId}
        onChange={handleChangePatrimonio}
        selectedValue={defaultValuePatrimonio()}
        /> */}
      <FieldSelectPatrimonioFinanceiroOld
        onSelect={handleChangePatrimonio}
        defaultValue={item?.patrimonioId ?? undefined}
        selectedId={defaultValuePatrimonio()?.id ?? item?.patrimonioId ?? undefined}
      />

      {isRendaFixaPos && !isSaling && (
        <Col lg="6" xl="6">
          <div>
            <label>Taxa *</label>
            <CurrencyInput
              id="lancamentoTaxaCustomizada"
              value={
                item?.taxaCustomizadaFormatada != null
                  ? item?.taxaCustomizadaFormatada
                  : item?.taxaCustomizada
              }
              thousandSeparator={'.'}
              decimalSeparator={','}
              precision="2"
              suffix=" %"
              onChangeEvent={(_, formattedValue, value) => {
                setItem({
                  taxaCustomizada: value,
                  taxaCustomizadaFormatada: formattedValue
                })
                // parentThis?.setState({
                //   item: {
                //     ...item,
                //     taxaCustomizada: value,
                //     taxaCustomizadaFormatada: formattedValue
                //   }
                // })
              }}
            />
          </div>
        </Col>
      )}

      {isRendaFixaPre && !isSaling && (
        <Col lg="6" xl="6">
          <div>
            <label>Taxa Anual *</label>
            <CurrencyInput
              id="lancamentoTaxaAnual"
              value={item?.taxaAnualFormatada != null ? item?.taxaAnualFormatada : item?.taxaAnual}
              thousandSeparator={'.'}
              decimalSeparator={','}
              precision="2"
              suffix=" %"
              onChangeEvent={(_event, formattedValue, value) => {
                setItem({
                  taxaAnual: value,
                  taxaAnualFormatada: formattedValue
                })
                // parentThis?.setState({
                //   item: {
                //     ...item,
                //     taxaAnual: value,
                //     taxaAnualFormatada: formattedValue
                //   }
                // })
              }}
            />
          </div>
        </Col>
      )}

      {isRendaFixa && !isSaling && (
        <FieldSelectDate
          id="lancamentoDataVencimento"
          label="Data de Vencimento *"
          onChange={(date: Date) => {
            // console.log('change data')
            setItem({
              dataVencimento: date
            })
          }}
          // @ts-expect-error //datavencimento
          selectedValue={item?.dataVencimento}
        />
        // <Col lg="6" xl="6">
        //   <div>
        //     <label>Data de Vencimento *</label>
        //     {parentThis?.gerarDatePicker(
        //       'dataVencimento',
        //       (_, date) => {
        //         parentThis?.setState({
        //           item: {
        //             ...item,
        //             dataVencimento: date
        //           }
        //         })
        //       },
        //       parentThis?.moment()
        //     )}
        //   </div>
        // </Col>
      )}

      {isRendaFixa && isSaling && (
        <Col lg="6" xl="6">
          <div>
            <label>Ativo *</label>
            {parentThis?.montarDropDown(
              state.patrimonioAtivosCombo,
              'lancamentoPatrimonioAtivo',
              ({ value }) => {
                setItem({
                  lancamentoPatrimonioAtivo: value
                })
                // parentThis?.setState({ item: { ...item, lancamentoPatrimonioAtivo: value } })
              },
              item?.lancamentoPatrimonioAtivo
            )}
          </div>
        </Col>
      )}

      {/* {item?.patrimonio !== null && item?.patrimonio?.tipoPatrimonio !== 5 && !isSaling && (
        <FieldSelectPaymentMethods
          label="Origem do Recurso"
          defaultValue={
            item?.meioPagamentoId ??
            item?.financialInstitutionCode ??
            item?.meioPagamento?.id ??
            paymentMethod?.id
          }
          onSelect={({ value }) => {
            parentThis?.setState({
              item: {
                ...item,
                meioPagamento: value,
                meioPagamentoId: value?.id
              }
            })
          }}
        />
      )} */}

      <FieldSelectFinancialInstitutions
        label="Instituição Financeira da Transação *"
        id="investimentoInstituicaoFinanceira"
        onSelect={({ value }) => {
          setItem({
            idInstituicaoFinanceira: value?.id ?? value?.key
          })
          // parentThis?.setState({
          //   item: {
          //     ...item,
          //     idInstituicaoFinanceira: value?.id ?? value?.key
          //   }
          // })
        }}
        defaultValue={item?.idInstituicaoFinanceira ?? item?.financialInstitutionCode}
      />
      {/* <Col lg="6" xl="6" className="lancamento investimento">
        <div>
          <label>Instituição Financeira da Transação *</label>
          {parentThis?.montarDropDown(
            state.instituicoesFinanceiras,
            'lancamentoIdInstituicaoFinanceira',
            ({ value }) => {
              parentThis?.setState({
                item: {
                  ...item,
                  idInstituicaoFinanceira: value
                }
              })
            },
            item?.idInstituicaoFinanceira
          )}
        </div>
      </Col> */}

      <Col lg="6" xl="6" className="lancamento investimento">
        {/* TESOURO DIRETO, ACOES, MOEDA, PROD CUSTOMIZADO */}
        <div>
          <label className="lancamento investimento">
            {priceTitle} *
            {isSaling && !!patrimonioSelecionado && (
              <button
                style={{
                  borderRadius: 25,
                  border: 'solid 1px #09a8c2',
                  fontSize: 12,
                  cursor: 'pointer',
                  marginLeft: 5,
                  background: 'none'
                }}
                onClick={() => parentThis?.handleResgateTotal()}
              >
                <ResgatarTudo fontSize="small" style={{ color: '#09a8c2' }} />
                <span style={{ color: '#5b5c5d' }}>Resgate total</span>
              </button>
            )}
          </label>

          <CurrencyInput
            id="lancamentoValor"
            style={{ height: 38, paddingLeft: 25 }}
            value={item?.valor}
            // defaultValue={getInputAmount()}
            thousandSeparator={'.'}
            decimalSeparator={','}
            precision="2" //prefix={'R$ '}
            onChangeEvent={(_, formattedValue, value) => {
              if (isEditing) {
                setItem({
                  valor: value,
                  editRepeated: isRepeated,
                  editInstallments: isParceled,
                  valorParcela: isParceled || isRepeated ? value : null,
                  parcelado: isParceled || isRepeated,
                  parcelas: isParceled || isRepeated ? item?.parcelas : null,
                  valorFormatado: formattedValue
                })
                return
              }

              setItem({
                valor: value,
                valorFormatado: formattedValue
              })
            }}
          />
        </div>
      </Col>

      {investmentGroup && (
        <Col lg="6" xl="6">
          {/* TESOURO DIRETO, ACOES, MOEDA, PROD CUSTOMIZADO */}
          <div>
            <label>Quantidade *</label>

            <CurrencyInput
              id="lancamentoQuantidade"
              style={{ paddingLeft: 25 }}
              value={item?.quantidadeFormatado ? item?.quantidadeFormatado : item?.quantidade}
              thousandSeparator={'.'}
              decimalSeparator={','}
              onChangeEvent={(_, formattedValue, value) => {
                setItem({
                  quantidade: value,
                  quantidadeFormatado: formattedValue
                })
                // parentThis?.setState({
                //   item: {
                //     ...item,
                //     quantidade: value,
                //     quantidadeFormatado: formattedValue
                //   }
                // })
              }}
            />
          </div>
        </Col>
      )}

      {investmentGroup && (
        <Col lg="6" xl="6">
          {/* TESOURO DIRETO, ACOES, MOEDA, PROD CUSTOMIZADO */}
          <div>
            <label>
              {item?.patrimonio != null &&
              (item?.patrimonio?.tipoInvestimento === 8 ||
                item?.patrimonio?.tipoInvestimento === 10 ||
                item?.patrimonio?.tipoInvestimento === 99)
                ? item?.operacao !== 1
                  ? 'Valor Total Aplicado'
                  : 'Valor Total Resgatado'
                : 'Preço Unitário'}
            </label>
            {getAmonut()}
            {/* {item?.patrimonio != null &&
            (item?.patrimonio.tipoInvestimento === 8 ||
              item?.patrimonio.tipoInvestimento === 10 ||
              item?.patrimonio.tipoInvestimento === 99)
              ? (
                  (item?.valor != null ? item?.valor : 0) *
                  (item?.quantidade != null && item?.quantidade >= 0 ? item?.quantidade : 0)
                ).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              : item?.patrimonio != null && item?.patrimonio.tipoInvestimento === 4 && item?.operacao === 1
              ? (item?.valor != null ? item?.valor : 0).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              : (
                  (item?.valor != null ? item?.valor : 0) /
                  (item?.quantidade != null && item?.quantidade > 0 ? item?.quantidade : 1)
                ).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 6
                })} */}
          </div>
        </Col>
      )}

      {/* <Col lg="6" xl="6" className="lancamento investimento">
        <div>
          <label className="lancamento investimento">Data da Compra / Venda *</label>

          {parentThis?.gerarDatePicker(
            'data',
            (_, date) => {
              parentThis?.setState({
                item: {
                  ...item,
                  data: date
                }
              })
            },
            parentThis?.moment()
          )}
        </div>
      </Col> */}
      <FieldSelectDate
        id="lancamentoData"
        onChange={(date: Date) => {
          // console.log('change data')
          setItem({
            data: date
          })
        }}
        selectedValue={item?.data}
      />

      {typeof item?.patrimonio?.id === 'number' &&
        [INVESTMENT_NAME_CODE.Fundos, INVESTMENT_NAME_CODE.Previdencia].includes(
          item?.patrimonio?.tipoInvestimento
        ) &&
        isSaling && (
          <Col lg="6" xl="6">
            <div>
              <label>Tipo de Resgate *</label>
              {parentThis?.montarDropDown(
                REDEEM_OPTIONS,
                'lancamentoTipoRetirada',
                ({ value }) => {
                  setItem({
                    tipoRetirada: value
                  })
                  // parentThis?.setState({
                  //   item: {
                  //     ...item,
                  //     tipoRetirada: value
                  //   }
                  // })
                },
                item?.tipoRetirada
              )}
            </div>
          </Col>
        )}

      {item?.patrimonio?.tipoInvestimento === INVESTMENT_NAME_CODE.Ações && !isSaling && (
        <Col lg="6" xl="6">
          <div>
            <label>Custo Operacional</label>
            <Input
              type="number"
              id="lancamentoCustoOperacional"
              value={item?.custoOperacional}
              onChange={(e) => {
                setItem({
                  custoOperacional: e.target.value
                })
                // parentThis?.setState({
                //   item: {
                //     ...item,
                //     custoOperacional: e.target.value
                //   }
                // })
              }}
            />
          </div>
        </Col>
      )}

      {typeof item?.patrimonio?.tipoInvestimento === 'number' &&
        investmentsGroup.includes(item?.patrimonio?.tipoInvestimento) &&
        isSaling && (
          <Col lg="6" xl="6">
            <div>
              <label>Imposto de Renda *</label>
              <CurrencyInput
                id="lancamentoImpostoRenda"
                style={{ paddingLeft: 25 }}
                value={item?.impostoRendaFormatado != null ? item?.impostoRendaFormatado : item?.impostoRenda}
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    impostoRenda: value,
                    impostoRendaFormatado: formattedValue
                  })
                  // parentThis?.setState({
                  //   item: {
                  //     ...item,
                  //     impostoRenda: value,
                  //     impostoRendaFormatado: formattedValue
                  //   }
                  // })
                }}
              />
            </div>
          </Col>
        )}

      {typeof item?.patrimonio?.tipoInvestimento === 'number' &&
        investmentsGroup.includes(item?.patrimonio?.tipoInvestimento) &&
        isSaling && (
          <Col
            lg="6"
            xl="6"
            // style={
            //   item?.patrimonio != null &&
            //   (item?.patrimonio.tipoInvestimento === 1 || //Fundos
            //     item?.patrimonio.tipoInvestimento === 2 || //Previdencia
            //     item?.patrimonio.tipoInvestimento === 3 || //Renda Fixa Pós
            //     item?.patrimonio.tipoInvestimento === 4 || //Tesouro Direto
            //     item?.patrimonio.tipoInvestimento === 6 || //Renda Fixa Pré
            //     item?.patrimonio.tipoInvestimento === 8 || //Ações
            //     item?.patrimonio.tipoInvestimento === 9) && //Debentures
            //   item?.operacao === 1
            //     ? {}
            //     : { display: 'none' }
            // }
          >
            <div>
              <label>IOF *</label>
              <CurrencyInput
                id="lancamentoIOF"
                style={{ paddingLeft: 25 }}
                value={item?.iofFormatado != null ? item?.iofFormatado : item?.iof}
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    iof: value,
                    iofFormatado: formattedValue
                  })
                }}
              />
            </div>
          </Col>
        )}

      <Col lg="6" xl="6" className="lancamento investimento">
        <div>
          <label>Descrição</label>
          <textarea
            id="lancamentoOQue"
            onChange={(e) => {
              setItem({
                descricao: e.target.value
              })
            }}
            value={item?.descricao}
            style={{ padding: 20 }}
          ></textarea>
        </div>
      </Col>

      <Col lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton
          disabled={!validForm || !item}
          transaction={item}
          beforeSave={() => {
            if (!item) return

            setTransaction(item)
          }}
        />
      </Col>
    </Row>
  )
}
