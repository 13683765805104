import React from 'react'

interface StarIcon {
  fill?: boolean
}
export default ({ fill }: StarIcon) => {
  return (
    <svg width="100%" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.27 16.18 19l-1.64-7.03L20 7.24l-7.19-.62L10 0 7.19 6.62 0 7.24l5.45 4.73L3.82 19 10 15.27Z"
        fill={fill ? '#FFE03A' : 'none'}
        style={{
          stroke: '#88888888',
          strokeWidth: !fill ? '1px' : '0px',
          transition: 'all .2s ease-in-out',
        }}
      />
    </svg>
  )
}
