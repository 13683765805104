import React, { useCallback, useState } from 'react'
import { useColumns } from './columns'
import { ErrorContainer, ExpandedContainer } from './CompaniesTable.styles'
import Button from '../../Buttons'
import { getLoadingCompanies } from '../../../domain/company'
import PlannersByCompanyIdTable from '../PlannersByCompanyIdTable'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { noticeError } from '../../../services/Monitoring'
import Table from '../Table'
import { useGetAllCompaniesQuery } from '../../../queries/company/queries'
import { TableFooter } from '../Footer'
import { FooterRow, Text } from '../Footer/Footer.styles'
import { formatMonetaryValue } from '../../../domain/formatter'
import { ContainerTable } from '../Table.styles'

interface Sort {
  prop: string
  desc: boolean
}

interface Props {
  marginBottom?: number
  filterDate: Date
}

const defaultSort: Sort = { desc: true, prop: 'totalInvoice' }
const loadingData = getLoadingCompanies()

export const CompaniesTable = ({ marginBottom, filterDate }: Props) => {
  const token = useRequiredAuth()
  const [search, setSearch] = useState<string>()
  const [page, setPage] = useState(0)
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage('companies-page-size', 10)
  const [sort, setSort] = useState<Sort>(defaultSort)
  const onSort = useCallback((columnId: string, order: 'desc' | 'asc' | 'none') => {
    if (order === 'none') setSort(defaultSort)
    else setSort({ desc: order === 'desc', prop: columnId })
  }, [])

  const { companies, isCompaniesError, isLoadingCompanies, companiesError, refetchGetAllCompanies } =
    useGetAllCompaniesQuery({
      desc: sort?.desc,
      orderBy: sort?.prop,
      pageIndex: page,
      pageSize,
      search,
      filterDate
    })
  const totalPages = companies ? Math.ceil(companies.companies.length / pageSize) : 1

  const columns = useColumns()

  if (isCompaniesError) {
    noticeError(companiesError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetAllCompanies()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable style={{ marginBottom }}>
      <Table
        title="Empresas"
        data={companies.companies || []}
        columns={columns}
        isLoading={isLoadingCompanies}
        loadingData={loadingData}
        filterEnabled={true}
        onFilterChange={setSearch}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        sortable={true}
        onSort={onSort}
        downloadFileName={`Empresas-${new Date().toISOString().split('T')[0]}`}
        renderExpanded={({ id }) => (
          <ExpandedContainer>
            <PlannersByCompanyIdTable companyId={id} filterDate={filterDate} />
          </ExpandedContainer>
        )}
        renderFooter={() => (
          <TableFooter>
            <FooterRow>
              <Text>Total de Empresas: {companies.totalCompanies || 0}</Text>
              <Text>Total de planejadores: {companies.totalPlanners || 0}</Text>
              <Text>Total de famílias: {companies.totalFamilies || 0}</Text>
              <Text>Total de Fatura: R$ {formatMonetaryValue(companies.totalInvoice || 0)}</Text>
            </FooterRow>
          </TableFooter>
        )}
        widthBeforeHorizontalScroll={1382}
      />
    </ContainerTable>
  )
}
