import React from 'react'

// Full gambiarra, meu deus que vergonha
const TreatOldDates = () => {
  let dataInicio = localStorage.getItem('dataInicio') ?? ''
  let dataFim = localStorage.getItem('dataFim') ?? ''
  const dataInicioPatrimonio = localStorage.getItem('dataInicioPatrimonio') ?? ''
  const dataFimPatrimonio = localStorage.getItem('dataFimPatrimonio') ?? ''

  const doubleQuotes = '"'

  if (dataInicio.startsWith(doubleQuotes)) dataInicio = dataInicio.substring(1)
  if (dataInicio.endsWith(doubleQuotes)) dataInicio = dataInicio.substring(0, -1)

  if (dataFim.startsWith(doubleQuotes)) dataFim = dataFim.substring(1)
  if (dataFim.endsWith(doubleQuotes)) dataFim = dataFim.substring(0, -1)

  if (dataInicioPatrimonio.startsWith(doubleQuotes)) localStorage.removeItem('dataInicioPatrimonio')
  if (dataInicioPatrimonio.endsWith(doubleQuotes)) localStorage.removeItem('dataInicioPatrimonio')

  if (dataFimPatrimonio.startsWith(doubleQuotes)) localStorage.removeItem('dataFimPatrimonio')
  if (dataFimPatrimonio.endsWith(doubleQuotes)) localStorage.removeItem('dataFimPatrimonio')

  localStorage.setItem('dataInicio', dataInicio)
  localStorage.setItem('dataFim', dataFim)

  return <></>
}

export default TreatOldDates
