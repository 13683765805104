import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { CompaniesTable, LonelyPlannersTable } from '../../../components/Tables'
import { Chip } from '../../../components/Chip'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { loginPath } from '../../../routes'
import { useFamilyBillingSummary } from '../../../queries/company/queries'
import { formatMonetaryValue } from '../../../domain/formatter'

export default ({ filterDate }) => {
  const token = useRequiredAuth()
  if (!token?.isSupport) return <Redirect to={loginPath} />

  const { familyBillingSummary, refetchGetFamilyBillingSummary } = useFamilyBillingSummary({ filterDate })

  useEffect(() => {
    refetchGetFamilyBillingSummary()
  }, [filterDate, refetchGetFamilyBillingSummary])

  return (
    <>
      <div style={{ display: 'flex', columnGap: 20, marginBottom: 20 }}>
        <Chip title={familyBillingSummary?.quantityOfFamilies || 0} subTitle="FAMÍLIAS" />
        <Chip
          title={`R$ ${formatMonetaryValue(familyBillingSummary?.totalInvoice || 0)}`}
          subTitle="FATURA"
        />
      </div>
      <CompaniesTable marginBottom={20} filterDate={filterDate} />
      <LonelyPlannersTable filterDate={filterDate} />
    </>
  )
}
