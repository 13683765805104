import { listThemes } from '../../domain/theme/api/listThemes'
import { useQuery } from 'react-query'

const cacheBaseKey = 'theme'

export const useThemesList = () => {
  const {
    data: themes,
    isError: isThemesListError,
    isLoading: isLoadingThemesList,
  } = useQuery<unknown, Error, string[]>([cacheBaseKey], listThemes)

  return { themes, isThemesListError, isLoadingThemesList }
}
