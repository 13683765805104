/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'

// reactstrap components
import { Row, Col } from 'reactstrap'

class AdminFooter extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col>
            {/* <div className="copyright text-center text-xl-left text-muted">
              © 2019{" "}
              Todos os direitos reservados 2
              <a className="font-weight-bold ml-1">
                Vista
              </a>
            </div> */}
          </Col>
        </Row>
      </footer>
    )
  }
}

export default AdminFooter
