import { deleteWithToken } from '../../../webClient'
import { Result } from '../../extrato/api/sendExtrato'

export interface DeleteSelectedFamiliesParams {
  ids: string
  reasonId: number
  reasonDescription: string
}

export interface DeleteSelectedFamiliesBody {
  cancellationReasonId: number
  cancellationReasonDescription: string
}

export type DeleteSelectedFamiliesResult = Result & {
  exception: { message: string }
}

export const deleteFamiliesById = async ({
  ids,
  reasonId,
  reasonDescription,
}: DeleteSelectedFamiliesParams) => {
  const body = {
    cancellationReasonId: reasonId,
    cancellationReasonDescription: reasonDescription,
  }
  const { data, status } = await deleteWithToken<
    DeleteSelectedFamiliesResult,
    DeleteSelectedFamiliesBody
  >(`/familia/bulk/${ids}`, body)
  if (status !== 200) {
    const msg = 'Error getting Cancellation Reasons'
    console.error(msg)
    throw new Error(msg)
  }
  return data
}
