import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Colors from '../../../domain/Colors'
import VerticalDivider from '../../dividers'
import { ArrowDown, Loading } from '../../Icons'
import { isClickOutside } from '../../Utils'
import Options, { Selectable } from './Options'

const Container = styled.div<{ open: boolean; borderless: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 1.9px solid
    ${({ borderless }) => (borderless ? Colors.disabledTextBackground : 'rgb(154, 61, 255)')};
  border-radius: ${({ open }) => (open ? '0.25rem' : '1.3rem')};
  padding-right: 0.2rem;
  min-width: 120px;
  min-height: 1rem;
  transition-property: border-radius, text-indent;
  transition-duration: 0.4s;
  background-color: ${({ borderless }) => (borderless ? Colors.disabledTextBackground : Colors.white)};
`

const SearchSizer = styled.div<{ open: boolean }>`
  display: flex;
  flex: 5;
  margin-left: ${({ open }) => (open ? '0.25rem' : '0.65rem')};
  transition-property: margin-left;
  transition-duration: 0.4s;
`

const SearchInput = styled.input<{ borderless: boolean }>`
  padding: 0;
  width: 95%;
  border: none;
  -webkit-appearance: none;
  background-color: ${({ borderless }) => (borderless ? Colors.disabledTextBackground : Colors.white)};
  &:focus {
    outline: none;
  }

  &[disabled] {
    opacity: 0.4;
  }
`

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: right;
  margin-right: 10px;
`

export interface Props<T> {
  filter?: string
  placeholder?: string
  options: Selectable<T>[]
  loading?: boolean
  grayscale?: boolean
  borderless?: boolean
  onSelection?: (value: T) => void
  onOpen?: () => void
  onClose?: () => void
  onFilter?: (filter: string) => void
  className?: string
  id?: string
  disabled?: boolean
}

export default <T,>({
  filter = '',
  options,
  disabled,
  placeholder,
  loading = false,
  grayscale = false,
  borderless = false,
  onSelection,
  onOpen,
  onClose,
  onFilter,
  className,
  id
}: Props<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const focusFilter = useCallback(() => {
    const { current } = inputRef
    if (!current) return
    current.focus()
  }, [inputRef])

  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState(false)
  const open = useCallback(() => {
    focusFilter()
    setOpen(true)
    onOpen?.()
  }, [setOpen, onOpen, focusFilter])
  const close = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [setOpen, onClose])
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      if (loading) return
      if (isOpen) close()
      else open()
    },
    [isOpen, open, close, loading]
  )

  // Close on click outside
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (isClickOutside(ref, event)) close()
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })

  const optionsAvailable = isOpen && !loading

  return (
    <Container
      ref={ref}
      open={optionsAvailable}
      borderless={borderless}
      onClick={onClick}
      className={className}
      id={id}
    >
      <SearchSizer open={optionsAvailable}>
        <SearchInput
          ref={inputRef}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          value={filter}
          borderless={borderless}
          onChange={({ target: { value } }) => onFilter?.(value)}
        />
      </SearchSizer>
      <IconContainer>
        {loading && <Loading />}
        <VerticalDivider color={grayscale ? 'gray' : 'darkGray'} />
        <ArrowDown grayscale={grayscale} />
      </IconContainer>
      {optionsAvailable && (
        <Options
          options={options}
          onSelection={(v) => {
            close()
            onSelection?.(v)
          }}
        />
      )}
    </Container>
  )
}
