import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect } from 'react'
import { createContext, FC } from 'react'
import { ThemeProvider } from './themeContext'
import { TransactionContextProvider } from './transactionContext'
import { useLocalStorage } from '../useLocalStorage'
import { InitialDataContextProvider } from './initialDataContext'
import { ThemesModal } from '../../components/Modal'
import TreatOldDates from '../../TreatOldDates'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useClearCacheEasterEgg } from '../useClearCacheEasterEgg'
import { useAuth } from './authContext'
import { useTheme } from '../useTheme'
import { SelectComboContextProvider } from './selectComboContext'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useFamilyQuery } from '../../queries/family/queries'
import { LoadingScreenProvider } from './loadingScreenContext'
import { LoadingScreen } from '../../components/Loading'

export interface ICoreContext {
  empresaId?: number | null
  familiaId?: number | null
  planejadorId?: number | null
  planejadorPrincipal?: number | string | null
  tipoUsuario?: number | string | null
  usuarioId?: number | null
  authenticated?: boolean
  userType?: {
    isPlanned?: boolean
    isPlanner?: boolean
    isManager?: boolean
    isSupport?: boolean
  }
  setFamiliaId: Dispatch<SetStateAction<string | null>>
  setPessoaId: Dispatch<SetStateAction<string | null>>
  setPlanejadorId: Dispatch<SetStateAction<string | null>>
  setPlanejadorPrincipal: Dispatch<SetStateAction<string | null>>
  setTheme: Dispatch<SetStateAction<string | null>>
  setTipoUsuario: Dispatch<SetStateAction<string | null>>
  setUsuarioEmail: Dispatch<SetStateAction<string | null>>
  setUsuarioId: Dispatch<SetStateAction<string | null>>
  setEmpresaId: Dispatch<SetStateAction<string | null>>
}

const CoreContext = createContext({
  authenticated: false,
  setFamiliaId: () => {},
  setEmpresaId: () => {},
  setPessoaId: () => {},
  setPlanejadorId: () => {},
  setPlanejadorPrincipal: () => {},
  setTheme: () => {},
  setTipoUsuario: () => {},
  setUsuarioEmail: () => {},
  setUsuarioId: () => {}
} as ICoreContext)

const MaybeReactQueryDevTools = () => {
  const isReactQueryDebuggingOn = process.env.REACT_APP_QUERY_DEBUG
  console.log(`React query debugging is ${isReactQueryDebuggingOn}`)
  return isReactQueryDebuggingOn === 'true' ? <ReactQueryDevtools /> : <></>
}

const ClearCacheContainer = () => {
  useClearCacheEasterEgg()
  return <></>
}

export const CoreContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { changeTheme } = useTheme()
  const auth = useAuth()
  const { authenticated, token, isPlanner, isPlanned, isManager, isSupport } = auth || {}
  const {
    email: usuarioEmail,
    empresaId,
    familiaId,
    id: usuarioId,
    pessoaId,
    planejadorId,
    theme,
    type: tipoUsuario
    // manager
  } = token || {}

  const { setValue: setEmpresaId } = useLocalStorage('empresa-id')
  const { setValue: setFamiliaId, value: storeFamiliaId } = useLocalStorage('familia-id')
  const { setValue: setPessoaId } = useLocalStorage('pessoa-id')
  const { setValue: setPlanejadorId } = useLocalStorage('planejador-id')
  const { setValue: setPlanejadorPrincipal } = useLocalStorage('planejador-principal')
  const { setValue: setTheme } = useLocalStorage('theme')
  const { setValue: setTipoUsuario } = useLocalStorage('tipo-usuario')
  const { setValue: setUsuarioEmail } = useLocalStorage('usuario-email')
  const { setValue: setUsuarioId } = useLocalStorage('usuario-id')
  const finalFamiliaId = storeFamiliaId ? Number(storeFamiliaId) : familiaId ?? null
  const { family } = useFamilyQuery(finalFamiliaId ? finalFamiliaId : undefined)
  const finalEmpresaId = empresaId ?? family?.empresaId

  // console.log({ token })
  useEffect(() => {
    if (authenticated) {
      // Os dados só são salvos no localStorage por retrocompatibilidade
      // com os componentes antigos. Os novos devem usar o authContext.
      if (finalEmpresaId) setEmpresaId(String(finalEmpresaId))
      if (familiaId || storeFamiliaId) setFamiliaId(String(familiaId ?? storeFamiliaId))
      if (pessoaId) setPessoaId(String(pessoaId))
      if (planejadorId) setPlanejadorId(String(planejadorId))
      if (tipoUsuario) setTipoUsuario(tipoUsuario)
      if (usuarioEmail) setUsuarioEmail(String(usuarioEmail))
      if (usuarioId) setUsuarioId(String(usuarioId))
      setPlanejadorPrincipal('1')
    }
  }, [
    authenticated,
    changeTheme,
    empresaId,
    familiaId,
    family,
    finalEmpresaId,
    pessoaId,
    planejadorId,
    setEmpresaId,
    setFamiliaId,
    setPessoaId,
    setPlanejadorId,
    setPlanejadorPrincipal,
    setTheme,
    setTipoUsuario,
    setUsuarioEmail,
    setUsuarioId,
    storeFamiliaId,
    theme,
    tipoUsuario,
    usuarioEmail,
    usuarioId
  ])

  useCallback(() => {
    if (theme) {
      setTheme(String(theme))
      changeTheme(theme)
    }
  }, [changeTheme, setTheme, theme])

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableRow: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: '#f8f9fe'
            }
          }
        },
        // @ts-expect-error
        MUIDataTableSelectCell: {
          fixedHeaderCommon: {
            backgroundColor: 'unset'
          }
        },
        MuiTableCell: {
          root: {
            fontFamily: 'unset',
            borderTop: 'none',
            padding: '16px 10px'
          },
          footer: {
            color: '#FFF',
            fontWeight: 700
          }
        },
        MUIDataTableHeadCell: {
          fixedHeaderYAxis: {
            fontWeight: 700
          }
        },
        MuiPaper: {
          root: {
            overflow: 'hidden'
          }
        },
        MuiToolbar: {
          regular: {
            backgroundColor: '#e4eaf4'
          }
        },
        MUIDataTablePagination: {
          root: {
            '&:last-child': {
              margin: '0px 24px 0px 24px',
              padding: 0
            }
          }
        }
      }
    })

  return (
    <CoreContext.Provider
      value={{
        authenticated,
        empresaId: finalEmpresaId,
        familiaId: finalFamiliaId,
        planejadorId,
        tipoUsuario,
        usuarioId,
        userType: {
          isPlanner,
          isPlanned,
          isManager,
          isSupport
        },
        setEmpresaId,
        setFamiliaId,
        setPessoaId,
        setPlanejadorId,
        setPlanejadorPrincipal,
        setTheme,
        setTipoUsuario,
        setUsuarioEmail,
        setUsuarioId
      }}
    >
      <ThemeProvider>
        <MuiThemeProvider theme={getMuiTheme()}>
          <LoadingScreenProvider>
            <InitialDataContextProvider>
              <SelectComboContextProvider>
                <TransactionContextProvider>
                  {children}
                  <LoadingScreen />
                  <ThemesModal />
                  <TreatOldDates />
                  <MaybeReactQueryDevTools />
                  <ClearCacheContainer />
                </TransactionContextProvider>
              </SelectComboContextProvider>
            </InitialDataContextProvider>
          </LoadingScreenProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </CoreContext.Provider>
  )
}

export const useCoreContext = () => {
  const context = useContext(CoreContext)
  if (!context) {
    throw new Error('CoreContext ===> Need Wrap User container')
  }
  return context
}
